import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
import classnames from "classnames";
import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { Heading, Label, MuiIcon, Paragraph, RegularButton } from "@web/ui";
import { formatFileSize } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";

import { ACCEPTED_FILE_EXTENSIONS, MAX_FILE_SIZE_IN_BYTES } from "../../config";
import { useAttachmentUpload } from "../../hooks";
import { Attachment, AttachmentForm, AttachmentsForm } from "../../models";
import { AttachmentsService } from "../../services";
import { AttachmentController } from "../AttachmentController";
import { FileSelectController } from "../FileSelectController";

interface Props {
  attachments: Attachment[];
  cancel: () => void;
  submit: (attachments: Attachment[]) => void;
  isSubmitting?: boolean;
  isSubmittingExternallyDisabled?: boolean;
  onSetIsDirty?: (isDirty: boolean) => void;
}

export const AttachmentsModal: React.FC<Props> = ({
  attachments,
  submit,
  isSubmitting = false,
  isSubmittingExternallyDisabled = false,
  cancel,
  onSetIsDirty,
}) => {
  const { setSystemMessage } = useSystemMessages();

  const attachmentsForm = useForm<AttachmentsForm>({
    mode: "onChange",
    defaultValues: {
      attachments,
    },
  });

  const { control, handleSubmit, formState, getValues: getFormValues } = attachmentsForm;
  const { isDirty, isValid } = formState;

  useEffect(() => onSetIsDirty && onSetIsDirty(isDirty), [onSetIsDirty, isDirty]);

  useEffect(() => {
    // When `attachments` passed down to this component change, reset the form with new data
    attachmentsForm.reset({
      attachments,
    });
  }, [attachmentsForm, attachments]);

  const {
    fields: editedAttachments,
    remove: removeFormAttachment,
    update: updateFormAttachment,
    append: appendFormAttachment,
  } = useFieldArray({
    control,
    name: "attachments",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const { fileSelectSuccess, fileSelectError, cancelUpload, doesUploadExist, isAnyUploadPending } =
    useAttachmentUpload(
      editedAttachments,
      removeFormAttachment,
      updateFormAttachment,
      appendFormAttachment,
      getFormValues
    );

  const hasAttachments = editedAttachments.length > 0;

  const restoreAttachment = (attachment: AttachmentForm, attachmentIndex: number) => {
    updateFormAttachment(attachmentIndex, {
      ...attachment,
      isRemoved: false,
    });
  };

  const removeAttachment = (attachment: AttachmentForm, attachmentIndex: number) => {
    updateFormAttachment(attachmentIndex, {
      ...attachment,
      isRemoved: true,
    });
  };

  const submitFormData = () => {
    handleSubmit(
      (attachmentsFormData) => {
        const attachmentsToSubmit = attachmentsFormData.attachments
          .filter((attachment) => !attachment.isRemoved)
          .map((attachment) =>
            AttachmentsService.convertFromFormAttachmentToAttachment(attachment)
          );
        submit(attachmentsToSubmit);
        // Do not reset form data here, as the submit function passed from above can result
        // in errors, handled by one of the parents of this component.
        // Let the parent pass down new `attachments` collection in props when they get saved,
        // so the `useEffect` will reset the form data with new `attachments` instance.
      },
      () => {
        setSystemMessage({
          type: "failure",
          message: "Some of the attachments contain invalid data. Please amend them and try again.",
        });
      }
    )().catch(console.error);
  };

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left shadow-xl transform max-w-2xl w-full align-middle p-5"
      data-testid="attachmentsModal"
    >
      <div className="absolute top-0 right-0 pt-5 px-5">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary"
          onClick={cancel}
          data-testid="attachmentsModal_closeButton"
        >
          <span className="sr-only">Close modal</span>
          <MuiIcon
            Icon={CloseOutlinedIcon}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <Heading size="300">Attachments</Heading>

      <div
        className={classnames("min-h-[320px] py-2", {
          "flex flex-col": !hasAttachments,
        })}
      >
        {!hasAttachments && (
          <Paragraph
            size="300"
            color="text-textIcon-blackSecondary"
            className="flex flex-1 justify-center items-center h-full"
          >
            This order doesn&apos;t have any attachments yet.
          </Paragraph>
        )}

        {/* Match headers with File component "columns" */}
        {hasAttachments && (
          <div className="flex justify-between pl-4 pr-2">
            <div className="flex-1 -ml-2">
              <Label size="300" color="text-textIcon-blackSecondary" className="font-normal">
                Details
              </Label>
            </div>
            <div className="flex-none flex justify-between">
              <div className="text-center w-[100px] ml-2">
                <Label size="300" color="text-textIcon-blackSecondary" className="font-normal">
                  Size
                </Label>
              </div>
              <div className="w-[130px] ml-2">
                <Label size="300" color="text-textIcon-blackSecondary" className="font-normal">
                  Category
                </Label>
              </div>
              <div className="w-[60px] ml-2"></div>
            </div>
          </div>
        )}

        <FormProvider {...attachmentsForm}>
          {editedAttachments.map((attachment, index) => (
            <AttachmentController
              key={attachment.id}
              attachment={attachment}
              attachmentIndex={index}
              cancelUpload={cancelUpload}
              restoreAttachment={restoreAttachment}
              removeAttachment={removeAttachment}
              className="mt-2"
              testId={`attachmentsModal_attachment_${index}`}
            />
          ))}
        </FormProvider>
      </div>

      <FileSelectController
        acceptedFileExtensions={ACCEPTED_FILE_EXTENSIONS}
        maxSizeInBytes={MAX_FILE_SIZE_IN_BYTES}
        doesUploadExist={doesUploadExist}
        fileSelectSuccess={fileSelectSuccess}
        fileSelectError={fileSelectError}
        render={(triggerFileSelection) => (
          <RegularButton
            className="shadow-sm mt-2"
            variant="secondary"
            size="large"
            LeadingIcon={UploadIcon}
            label="Upload file from computer"
            onClick={triggerFileSelection}
            disabled={isSubmitting}
            data-testid="attachmentsModal_uploadFileButton"
          />
        )}
      />
      <div className="flex justify-between items-center mt-2">
        <div>
          <Label
            size="300"
            className="tracking-normal block text-textIcon-whiteDisabled font-normal"
          >
            Maximum size per file: {formatFileSize(MAX_FILE_SIZE_IN_BYTES)}.
          </Label>
          <Label
            size="300"
            className="tracking-normal block mt-0.5 text-textIcon-whiteDisabled font-normal"
          >
            Supported formats:{" "}
            {AttachmentsService.formatAcceptedFileFormats(ACCEPTED_FILE_EXTENSIONS)}.
          </Label>
        </div>
        <div className="flex">
          <RegularButton
            className="mr-2"
            variant="secondary"
            size="large"
            label="Close"
            onClick={cancel}
            disabled={isSubmitting}
            data-testid="attachmentsModal_cancelButton"
          />
          <RegularButton
            variant="primary"
            size="large"
            label="Save changes"
            disabled={
              !isDirty ||
              !isValid ||
              isAnyUploadPending ||
              isSubmitting ||
              isSubmittingExternallyDisabled
            }
            loading={isSubmitting || isSubmittingExternallyDisabled}
            onClick={submitFormData}
            data-testid="attachmentsModal_saveButton"
          />
        </div>
      </div>
    </div>
  );
};
