import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";

import { Heading, Loading, MuiIcon, Paragraph } from "@web/ui";

import { RoutesParams } from "src/admin/config/routes";
import { SupplierFormType, emptySupplierState, useSupplierQuery } from "src/domain";

import { EditSupplierForm } from "./EditSupplierForm";

export const EditSupplierFormQuery: React.FC = () => {
  const { [RoutesParams.SUPPLIER_ID]: supplierId } = useParams();
  const supplierQuery = useSupplierQuery(
    { supplierId: supplierId || "" },
    { enabled: !!supplierId }
  );

  // TODO 2159: Make sure below errors are handled correctly
  if (!supplierId) {
    return (
      <div className="container-md mx-auto mt-8 flex-1">
        <MuiIcon Icon={InfoOutlined} className="w-8 mx-auto" />
        <>
          <Heading size="300">Inconsistent application state</Heading>
          <Paragraph size="200">Missing supplierId</Paragraph>
        </>
      </div>
    );
  }

  if (!supplierQuery.isSuccess) {
    return <Loading className="m-auto" />;
  }

  const defaultForm = supplierQuery.data.information
    ? (supplierQuery.data as SupplierFormType)
    : emptySupplierState;

  return <EditSupplierForm defaultForm={defaultForm} supplierId={supplierId} />;
};
