import { RewindIcon } from "@heroicons/react/solid";

import { DangerConfirmationModal } from "@web/ui";

import { useSystemMessages } from "src/context/SystemMessages";

import { useDiscardOrderChangesMutation } from "../../hooks";

type Props = {
  orderId: string;
  closeModal: () => void;
  onRestoreSuccess?: () => void;
};

export const RestoreOriginalOrderModal = ({ orderId, closeModal, onRestoreSuccess }: Props) => {
  const { setSystemMessage } = useSystemMessages();

  const restoreOriginalOrderMutation = useDiscardOrderChangesMutation({
    onSuccess: () => {
      setSystemMessage({
        type: "success",
        message: "Original order was restored.",
      });
      closeModal();
      if (onRestoreSuccess) {
        onRestoreSuccess();
      }
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while restoring original order. Please try again.",
      });
    },
  });

  const isModalBlocked =
    restoreOriginalOrderMutation.isPending || restoreOriginalOrderMutation.isSuccess;

  const handleConfirmOriginalOrderRestore = () => {
    if (isModalBlocked) {
      return;
    }

    restoreOriginalOrderMutation.mutate({
      s2SOrderId: orderId,
    });
  };

  return (
    <DangerConfirmationModal
      title="Are you sure you want to restore the original order?"
      subtitle="All changes made to the order will be discarded, and the original order data will be restored. This action cannot be undone."
      confirmButtonLabel="Restore Original Order"
      closeButtonLabel="Cancel"
      onConfirm={handleConfirmOriginalOrderRestore}
      onClose={closeModal}
      Icon={RewindIcon}
      testIdPrefix="restoreOriginalOrderModal"
    />
  );
};
