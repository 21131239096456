import AddOutlined from "@mui/icons-material/AddOutlined";
import classnames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFieldArrayAppend, UseFieldArrayRemove } from "react-hook-form";

import { Heading, Paragraph, RegularButton } from "@web/ui";
import { isDefined } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";
import {
  AdditionalCostModalController,
  DiscountModalController,
  PriceModifier,
  PriceModifierRemoval,
  SupplierOrderAmountAdditionalCostForm,
  SupplierOrderAmountDiscountForm,
  SupplierOrderService,
  ValidatedSupplierOrderForm,
  isApiError,
  useAddAdditionalCostApiError,
  useAddDiscountApiError,
  useAddPriceModifierMutation,
  useOrderEditsSync,
} from "src/domain";
import {
  ApiError,
  PriceModifierInformation,
  PriceModifierInformationChangeResponse,
} from "src/typegens";

interface Props {
  className?: string;
  orderId: string;
  orderVersionId: number;
  currencyCode: string;
  currentTotalAmount: number;
  showGenericSavingError: () => void;
  formAmountAdditionalCosts: SupplierOrderAmountAdditionalCostForm[];
  formAmountDiscounts: SupplierOrderAmountDiscountForm[];
  appendFormAmountAdditionalCost: UseFieldArrayAppend<
    ValidatedSupplierOrderForm,
    "amountAdditionalCosts"
  >;
  removeFormAmountAdditionalCost: UseFieldArrayRemove;
  appendFormAmountDiscount: UseFieldArrayAppend<ValidatedSupplierOrderForm, "amountDiscounts">;
  removeFormAmountDiscount: UseFieldArrayRemove;
  onSetIsDirty: (isDirty: boolean) => void;
  onSetNewOrderVersion: (orderVersionId: number) => void;
}

export const OrderEditPriceModifiers: React.FC<Props> = ({
  className = "",
  appendFormAmountAdditionalCost,
  appendFormAmountDiscount,
  showGenericSavingError,
  orderId,
  orderVersionId,
  formAmountAdditionalCosts,
  removeFormAmountAdditionalCost,
  formAmountDiscounts,
  removeFormAmountDiscount,
  currencyCode,
  currentTotalAmount,
  onSetIsDirty,
  onSetNewOrderVersion,
}) => {
  const [isAdditionalCostDirty, setIsAdditionalCostDirty] = useState(false);
  const [isDiscountDirty, setIsDiscountDirty] = useState(false);

  const { setSystemMessage } = useSystemMessages();

  const { showAddAdditionalCostApiError } = useAddAdditionalCostApiError();
  const { showAddDiscountApiError } = useAddDiscountApiError();
  const {
    addEdit,
    isSyncInProgress: isSubmittingExternallyDisabled,
    editsToRollback,
    didEditsToRollbackChange,
  } = useOrderEditsSync();

  useEffect(
    () => onSetIsDirty(isAdditionalCostDirty || isDiscountDirty),
    [onSetIsDirty, isAdditionalCostDirty, isDiscountDirty]
  );

  const erroredPriceModifierRemovals = useMemo(
    () =>
      editsToRollback.filter(
        (edit) => edit.type === "priceModifierRemoval"
      ) as PriceModifierRemoval[],
    [editsToRollback]
  );

  const appendFormPriceModifier = useCallback(
    (apiPriceModifierOrRestoredModifier: PriceModifierInformation, isRestorationAction = false) => {
      const convertedPriceModifier = isRestorationAction
        ? apiPriceModifierOrRestoredModifier
        : SupplierOrderService.convertFromApiPriceModifierToOrderPriceModifier(
            apiPriceModifierOrRestoredModifier
          );

      switch (apiPriceModifierOrRestoredModifier.changeType) {
        case "ADDITIONAL":
          appendFormAmountAdditionalCost(convertedPriceModifier);
          return;
        case "DISCOUNT":
          appendFormAmountDiscount(convertedPriceModifier);
          return;
        // no default
      }
    },
    [appendFormAmountAdditionalCost, appendFormAmountDiscount]
  );

  const removePriceModifier = useCallback(
    (
      priceModifierToRemove:
        | SupplierOrderAmountAdditionalCostForm
        | SupplierOrderAmountDiscountForm,
      changeType: "ADDITIONAL" | "DISCOUNT"
    ) => {
      let collectionToRemoveFrom:
        | SupplierOrderAmountAdditionalCostForm[]
        | SupplierOrderAmountDiscountForm[];
      let removeFromCollection: UseFieldArrayRemove;

      switch (changeType) {
        case "ADDITIONAL":
          collectionToRemoveFrom = formAmountAdditionalCosts;
          removeFromCollection = removeFormAmountAdditionalCost;
          break;
        case "DISCOUNT":
          collectionToRemoveFrom = formAmountDiscounts;
          removeFromCollection = removeFormAmountDiscount;
          break;
        /* no default */
      }

      const priceModifierToRemoveIndex = collectionToRemoveFrom.findIndex(
        (priceModifier) => priceModifier.id === priceModifierToRemove.id
      );

      if (!isDefined(priceModifierToRemoveIndex)) {
        return;
      }

      // Optimistically remove from the UI
      removeFromCollection(priceModifierToRemoveIndex);

      // Synchronize with API
      addEdit({
        type: "priceModifierRemoval",
        meta: {
          orderId,
          oldOrderVersionId: orderVersionId,
          // TODO #7596: [CHORE] Fix types so that there are no excessive fields among change types
          orderItemId: "",
        },
        oldValue: {
          ...priceModifierToRemove,
          changeType,
        },
        newValue: undefined,
      });
    },
    [
      addEdit,
      orderId,
      orderVersionId,
      formAmountAdditionalCosts,
      removeFormAmountAdditionalCost,
      formAmountDiscounts,
      removeFormAmountDiscount,
    ]
  );

  // Handle restoration of those removals, which errored during sync
  useEffect(() => {
    if (!didEditsToRollbackChange) {
      return;
    }

    // Restore all removals whose syncs failed
    const priceModifiersToRestore: PriceModifierInformation[] = erroredPriceModifierRemovals.map(
      (item) => ({
        ...item.oldValue,
      })
    );

    priceModifiersToRestore.forEach((priceModifier) => {
      appendFormPriceModifier(priceModifier, true);
    });
  }, [didEditsToRollbackChange, erroredPriceModifierRemovals, appendFormPriceModifier]);

  const addAdditionalCostMutation = useAddPriceModifierMutation({
    onSuccess: (apiAdditionalCost: PriceModifierInformationChangeResponse) => {
      setSystemMessage({
        type: "success",
        message: "Additional cost was added to the order.",
      });

      appendFormPriceModifier(apiAdditionalCost.priceModifier);
      onSetNewOrderVersion(apiAdditionalCost.newVersion);
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    onError: (error: ApiError | unknown) => {
      if (isApiError(error)) {
        showAddAdditionalCostApiError(error);
        return;
      }

      showGenericSavingError();
    },
  });

  const submitAdditionalCost = async (
    additionalCost: SupplierOrderAmountAdditionalCostForm
  ): Promise<void> => {
    if (isSubmittingExternallyDisabled) {
      return;
    }

    const requestAdditionalCost =
      SupplierOrderService.convertFromFormPriceModifierToPriceModifierRequest(
        additionalCost,
        "ADDITIONAL",
        orderVersionId
      );

    await addAdditionalCostMutation.mutateAsync({
      s2SOrderId: orderId,
      priceModifier: {
        ...requestAdditionalCost,
      },
    });
  };

  const removeAdditionalCost = (additionalCostId: string) => {
    const additionalCostToRemove = formAmountAdditionalCosts.find(
      (additionalCost) => additionalCost.id === additionalCostId
    );
    if (!additionalCostToRemove) {
      return;
    }
    removePriceModifier(additionalCostToRemove, "ADDITIONAL");
  };

  const addDiscountMutation = useAddPriceModifierMutation({
    onSuccess: (apiDiscount: PriceModifierInformationChangeResponse) => {
      setSystemMessage({
        type: "success",
        message: "Discount was added to the order.",
      });

      appendFormPriceModifier(apiDiscount.priceModifier);
      onSetNewOrderVersion(apiDiscount.newVersion);
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    onError: (error: ApiError | unknown) => {
      if (isApiError(error)) {
        showAddDiscountApiError(error);
        return;
      }

      showGenericSavingError();
    },
  });

  const submitDiscount = async (discount: SupplierOrderAmountDiscountForm) => {
    if (isSubmittingExternallyDisabled) {
      return;
    }

    const requestDiscount = SupplierOrderService.convertFromFormPriceModifierToPriceModifierRequest(
      discount,
      "DISCOUNT",
      orderVersionId
    );

    await addDiscountMutation.mutateAsync({
      s2SOrderId: orderId,
      priceModifier: {
        ...requestDiscount,
      },
    });
  };

  const removeDiscount = (discountId: string) => {
    const discountToRemove = formAmountDiscounts.find((discount) => discount.id === discountId);
    if (!discountToRemove) {
      return;
    }

    removePriceModifier(discountToRemove, "DISCOUNT");
  };

  const doAmountAdditionalCostsExist = formAmountAdditionalCosts.length > 0;
  const doAmountDiscountsExist = formAmountDiscounts.length > 0;

  const isAdditionalCostAdditionSubmitting = addAdditionalCostMutation.isPending;
  const isDiscountAdditionSubmitting = addDiscountMutation.isPending;

  return (
    <div className={classnames("flex flex-col w-full", className)}>
      <div className="flex justify-between items-center mb-1">
        <Heading size="300" className="text-textIcon-blackPrimary">
          Additional costs and discounts
        </Heading>
        <div className="flex justify-end gap-3">
          <AdditionalCostModalController
            currencyCode={currencyCode}
            currentTotalAmount={currentTotalAmount}
            submitAdditionalCost={submitAdditionalCost}
            onSetIsDirty={setIsAdditionalCostDirty}
            isSubmitting={isAdditionalCostAdditionSubmitting}
            isSubmittingExternallyDisabled={isSubmittingExternallyDisabled}
            render={(openAdditionalCostModal) => (
              <RegularButton
                variant="secondary"
                size="large"
                label="Add cost"
                LeadingIcon={AddOutlined}
                onClick={openAdditionalCostModal}
                data-testid="openAdditionalCostModalButton"
              />
            )}
          />
          <DiscountModalController
            currencyCode={currencyCode}
            currentTotalAmount={currentTotalAmount}
            submitDiscount={submitDiscount}
            onSetIsDirty={setIsDiscountDirty}
            isSubmitting={isDiscountAdditionSubmitting}
            isSubmittingExternallyDisabled={isSubmittingExternallyDisabled}
            render={(openDiscountModal) => (
              <RegularButton
                variant="secondary"
                size="large"
                label="Add discount"
                LeadingIcon={AddOutlined}
                onClick={openDiscountModal}
                data-testid="openDiscountModalButton"
              />
            )}
          />
        </div>
      </div>
      {doAmountAdditionalCostsExist || doAmountDiscountsExist ? (
        <div data-testid="priceModifiersContainer" className="min-w-full mt-4">
          {formAmountAdditionalCosts.map((amountAdditionalCost, index) => (
            <PriceModifier
              key={amountAdditionalCost.id}
              className={classnames({ "mt-2": index > 0 })}
              costName={amountAdditionalCost.name}
              amount={amountAdditionalCost.amount}
              onTrashClick={() => removeAdditionalCost(amountAdditionalCost.id)}
              testId="amountAdditionalCost"
            />
          ))}
          {formAmountDiscounts.map((amountDiscount, index) => (
            <PriceModifier
              key={amountDiscount.id}
              className={classnames({
                "mt-2": index > 0 || doAmountAdditionalCostsExist,
              })}
              costName={amountDiscount.name}
              amount={amountDiscount.amount}
              onTrashClick={() => removeDiscount(amountDiscount.id)}
              testId="amountDiscount"
            />
          ))}
        </div>
      ) : (
        <>
          <Paragraph size="200" color="text-textIcon-blackSecondary" className="mb-2">
            This order doesn’t have any added costs or discounts.
          </Paragraph>
          <hr />
        </>
      )}
    </div>
  );
};
