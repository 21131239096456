/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { AddUserRequest } from "../models/AddUserRequest";
import type { SupplierDeleteRequest } from "../models/SupplierDeleteRequest";
import type { SupplierInformationUpdateRequest } from "../models/SupplierInformationUpdateRequest";
import type { SupplierRequest } from "../models/SupplierRequest";
import type { SupplierResponse } from "../models/SupplierResponse";
import type { SuppliersResponse } from "../models/SuppliersResponse";

export class SupplierManagementService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addUserToSupplier(
    {
      requestBody,
    }: {
      requestBody: AddUserRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/supplier-portal/suppliers/users",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SuppliersResponse OK
   * @throws ApiError
   */
  public static getSuppliersInformation(
    {
      cursor,
    }: {
      cursor?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SuppliersResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/suppliers",
      query: {
        cursor: cursor,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierResponse OK
   * @throws ApiError
   */
  public static updateSupplierInformation(
    {
      requestBody,
    }: {
      requestBody: SupplierInformationUpdateRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/supplier-portal/suppliers",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierResponse OK
   * @throws ApiError
   */
  public static saveSuppliers(
    {
      requestBody,
    }: {
      requestBody: SupplierRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/suppliers",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierResponse OK
   * @throws ApiError
   */
  public static deleteSupplier(
    {
      requestBody,
    }: {
      requestBody: SupplierDeleteRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/supplier-portal/suppliers",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierResponse OK
   * @throws ApiError
   */
  public static getSupplierSelfInformation(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/suppliers/self",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierResponse OK
   * @throws ApiError
   */
  public static getSupplierInformation(
    {
      supplierId,
    }: {
      supplierId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/suppliers/get",
      query: {
        supplierId: supplierId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
