import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import { MuiIcon, Paragraph, RegularButton } from "@web/ui";

import { useSystemMessages } from "src/context/SystemMessages";

import { useOrderCloseMutation } from "../../hooks";

interface Props {
  orderId: string;
  orderVersion: number;
  closeModal: () => void;
  onClosureSuccess?: () => void;
}

export const CloseOrderModal = ({ orderId, orderVersion, closeModal, onClosureSuccess }: Props) => {
  const { setSystemMessage } = useSystemMessages();

  const closeOrderMutation = useOrderCloseMutation({
    onSuccess: () => {
      setSystemMessage({
        type: "success",
        message: "The order was closed. You can find it under Closed Orders.",
      });
      closeModal();
      if (onClosureSuccess) {
        onClosureSuccess();
      }
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while closing this order. Please try again.",
      });
    },
  });

  const isModalBlocked = closeOrderMutation.isPending || closeOrderMutation.isSuccess;

  const handleConfirmOrderClosure = () => {
    if (isModalBlocked) {
      return;
    }

    closeOrderMutation.mutate({
      s2SOrderId: orderId,
      requestBody: { version: orderVersion },
    });
  };

  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      data-testid="closeOrderModal"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
          data-testid="closeOrderModal_closeButton"
        >
          <span className="sr-only">Cancel & Close Modal</span>
          <MuiIcon
            Icon={CloseOutlinedIcon}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto shrink-0 flex items-center justify-center p-2 rounded-full bg-neutral_200 sm:mx-0">
          <Inventory2OutlinedIcon className="w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">Are you sure you want to close this order?</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Your order will be marked as closed. This action cannot be undone.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          label="Close Order"
          loading={isModalBlocked}
          disabled={isModalBlocked}
          onClick={handleConfirmOrderClosure}
          data-testid="closeOrderModal_confirmButton"
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          onClick={closeModal}
          data-testid="closeOrderModal_cancelButton"
        />
      </div>
    </div>
  );
};
