import { OfficeBuildingIcon } from "@heroicons/react/solid";

import { Paragraph } from "../../atoms/Typography";

interface DesiredDeliveryTimeProps {
  label?: string;
  invoiceAccount: InvoiceAccount;
  className?: string;
}

type InvoiceAccount = {
  taxNumber: string;
  registerOwner: string;
  billingAddressLine1: string;
  billingAddressLine2?: string;
  billingAddressLine3?: string;
  countryCode: string;
};

export const Invoicing: React.FC<DesiredDeliveryTimeProps> = ({
  label = "invoicing account",
  className = "",
  invoiceAccount,
}) => {
  return (
    <div className={`py-3 ${className}`}>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-3 uppercase">
        {label}
      </Paragraph>
      <div className="flex flex-row items-center">
        <div className="flex flex-row">
          <div>
            <OfficeBuildingIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
          </div>
          <div className="flex flex-col ml-3 flex-1 truncate">
            <div className="flex truncate">
              <Paragraph size="200" className="truncate">
                {invoiceAccount.registerOwner}
              </Paragraph>{" "}
            </div>
            <div className="mt-2">
              <Paragraph size="300" color="text-neutral_700" className="truncate">
                {invoiceAccount.billingAddressLine1}
              </Paragraph>
              {invoiceAccount.billingAddressLine2 && (
                <Paragraph size="300" color="text-neutral_700" className="truncate">
                  {invoiceAccount.billingAddressLine2}
                </Paragraph>
              )}
              {invoiceAccount.billingAddressLine3 && (
                <Paragraph size="300" color="text-neutral_700" className="truncate">
                  {invoiceAccount.billingAddressLine3}
                </Paragraph>
              )}
              <Paragraph size="300" color="text-neutral_700" className="truncate">
                {invoiceAccount.countryCode}
              </Paragraph>
            </div>
            <div className="mt-1">
              <Paragraph size="300" color="text-textIcon-blackSecondary" className="truncate">
                Tax number: {invoiceAccount.taxNumber}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
