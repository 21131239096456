import { useParams } from "react-router-dom";

import { Loading } from "@web/ui";

import { RoutesParams } from "src/config/routes";
import { SupplierOrderService, useOrderByIdQuery } from "src/domain";

import { ConfirmOrderContainer } from "./components/ConfirmOrderContainer";

export const ConfirmOrderPage = () => {
  const { [RoutesParams.ORDER_ID]: orderId } = useParams() as { orderId: string };

  const originalOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
  });
  const formDataQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
    select: SupplierOrderService.convertSupplierOrderToValidatedFormData,
    enabled: !!originalOrderQuery.data,
  });

  if (
    originalOrderQuery.isPending ||
    originalOrderQuery.isFetching ||
    !originalOrderQuery.data ||
    formDataQuery.isPending ||
    formDataQuery.isFetching ||
    !formDataQuery.data
  ) {
    return <Loading />;
  }

  return (
    <ConfirmOrderContainer
      editedOrder={formDataQuery.data}
      originalOrder={originalOrderQuery.data}
    />
  );
};
