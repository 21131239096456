import { RegularButton } from "@web/ui";

import { SupplierOrderItemForm } from "../models";
import { SupplierOrderService } from "../services";
import { OrderItemDetails } from "./OrderItemDetails";

interface Props {
  candidate: SupplierOrderItemForm;
  onConfirm: () => void;
  onReject: () => void;
  confirmButtonLabel: string;
}

export const Candidate = ({ candidate, onReject, onConfirm, confirmButtonLabel }: Props) => {
  const uiCandidate = SupplierOrderService.convertOrderItemDataToUiModel(
    candidate,
    candidate.lineNumber
  );

  return (
    <div>
      <OrderItemDetails orderItem={uiCandidate} isAvailable={true} testId="orderItemCandidate" />

      <div className="mt-6 flex justify-end gap-2">
        <RegularButton
          variant="secondary"
          size="large"
          label="Back"
          onClick={onReject}
          data-testid="candidate_reject"
        />
        <RegularButton
          variant="primary"
          size="large"
          label={confirmButtonLabel}
          onClick={onConfirm}
          data-testid="candidate_confirm"
        />
      </div>
    </div>
  );
};
