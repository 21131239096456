import { PropsWithChildren } from "react";

import { UserScopesContext } from "./UserScopesContext";
import { useUserScopesReducer } from "./reducer";

export const UserScopesContextProvider = ({ children }: PropsWithChildren) => {
  const [scope, dispatch] = useUserScopesReducer();

  return (
    <UserScopesContext.Provider value={[scope, dispatch]}>{children}</UserScopesContext.Provider>
  );
};
