import PencilIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import DotsVerticalIcon from "@mui/icons-material/MoreVert";

import { DividerDropdownItem, Dropdown, IconButton, Paragraph, RegularDropdownItem } from "@web/ui";

import { SupplierResponse } from "src/typegens";

const tdStyleText = "border-b-1 border-t-1 p-2 px-4 text-left truncate";

type Props = {
  supplier: SupplierResponse;
  onEdit(id: string): void;
  onDelete(id: string, name?: string): void;
};

export const TableRow: React.FC<Props> = ({ supplier, onEdit, onDelete }) => {
  const items = [
    {
      key: "editSupplier",
      renderComponent: () => (
        <RegularDropdownItem
          label="Edit Supplier"
          variant="basic"
          LeadingIcon={PencilIcon}
          onClick={() => onEdit(supplier.supplierId)}
          data-testid="editSupplierDropdownItem"
        />
      ),
    },
    {
      key: "dangerZoneDivider",
      renderComponent: () => <DividerDropdownItem />,
    },
    {
      key: "deleteSupplier",
      renderComponent: () => (
        <RegularDropdownItem
          label="Delete Supplier"
          variant="basic"
          LeadingIcon={DeleteOutlined}
          onClick={() => onDelete(supplier.supplierId, supplier.information?.vendor.vendorName)}
          data-testid="deleteSupplierDropdownItem"
        />
      ),
    },
  ];

  return (
    <tr data-testid="supplierRow" key={supplier.supplierId} className="bg-neutral_0 cursor-pointer">
      <td className={`border-l-1 rounded-l-md ${tdStyleText} max-w-0`}>
        <div className="flex flex-col">
          <div className="flex whitespace-nowrap">
            <Paragraph size="300">
              {supplier.information?.vendor.vendorName || "Supplier name"}
            </Paragraph>
          </div>
          <div className="flex pt-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              {supplier.information?.vendor.vatNumber || "VAT number"}
            </Paragraph>
          </div>
        </div>
      </td>

      <td className={`${tdStyleText} max-w-0`}>
        <div className="flex flex-col">
          <div className="flex whitespace-nowrap">
            <Paragraph size="300">Contact person name - missing</Paragraph>
          </div>
          <div className="flex pt-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              {supplier.information?.contactDetails.rfqEmail || "email@mailinator.com"}
            </Paragraph>
          </div>
        </div>
      </td>
      <td className={`${tdStyleText} max-w-0`}>
        <div className="flex flex-col">
          <div className="flex whitespace-nowrap">
            <Paragraph size="300" className="uppercase ">
              {supplier.information?.contactDetails.website || "website"}
            </Paragraph>
          </div>
          <div className="flex pt-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              {supplier.information?.contactDetails.phoneNumber || "phone number"}
            </Paragraph>
          </div>
        </div>
      </td>
      <td className={`${tdStyleText} max-w-0`}>
        <div className="flex flex-col">
          <div className="flex whitespace-nowrap">
            <Paragraph size="300">{supplier.information?.location.address || "address"}</Paragraph>
          </div>
          <div className="flex pt-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              {supplier.information?.location.zipCode || "zip code"}{" "}
              {supplier.information?.location.city || "city"}
            </Paragraph>
          </div>
        </div>
      </td>
      <td className={`${tdStyleText} max-w-0`}>
        <Paragraph size="300">
          {supplier.information?.location.countryCode || "Country code"}
        </Paragraph>
      </td>
      <td
        className={`border-r-1 rounded-r-md border-b-1 border-t-1 p-2 px-4 text-right pr-6 max-w-0`}
      >
        <Dropdown hAlignTo="end" vAlignTo="bottom" items={items}>
          <IconButton
            size="large"
            variant="secondary"
            shape="square"
            label="Actions"
            Icon={DotsVerticalIcon}
            data-testid="moreButton"
            isControlled
          />
        </Dropdown>
      </td>
    </tr>
  );
};
