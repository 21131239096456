import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";
import { useMemo } from "react";

import { TotalUnitOfMeasure } from "@web/common";
import { Money } from "@web/models";
import { MuiIcon, Tooltip } from "@web/ui";

import {
  OrderItem,
  OrderItemPriceTotals,
  SpacingVariant,
  SupplierOrderService,
  SupplierUiOrderItem,
} from "src/domain";

interface Props {
  orderItem: SupplierUiOrderItem;
  onItemClick?: () => void;
  onNameClick?: () => void;
  isRemoved?: boolean;
  isAvailable: boolean;
  unavailabilityReason?: string;
  showItemLineNumber?: boolean;
  keepLeftMargin?: boolean;
  spacing?: SpacingVariant;
  testId?: string;
}

export const OrderItemDetails = ({
  orderItem,
  onItemClick,
  onNameClick,
  isRemoved,
  isAvailable,
  unavailabilityReason,
  showItemLineNumber,
  keepLeftMargin,
  spacing,
  testId,
}: Props) => {
  const { lineNumber, impaCode, supplierIdentifier, measurementUnit, name, quantity, totalAmount } =
    orderItem;

  /**
   * TotalUnitOfMeasure
   */
  const salesEntityQuantity = useMemo(
    () => SupplierOrderService.getSalesEntityQuantity(orderItem),
    [orderItem]
  );

  /**
   * Unit total
   */
  const unitPrice: Money = useMemo(
    () => SupplierOrderService.getSingleItemPrice(orderItem),
    [orderItem]
  );

  return (
    <OrderItem
      isAvailable={isAvailable}
      isRemoved={isRemoved}
      impaCode={impaCode}
      itemId={supplierIdentifier}
      itemLineNumber={lineNumber}
      showItemLineNumber={showItemLineNumber}
      keepLeftMargin={keepLeftMargin}
      itemName={name}
      onItemClick={onItemClick}
      onNameClick={onNameClick}
      spacing={spacing}
      testId={testId}
      renderAdditionalData={(columnClassName) => (
        <div className="flex-none grid grid-cols-2">
          <div className={`flex flex-col items-start justify-around pl-4 ${columnClassName}`}>
            <TotalUnitOfMeasure
              className="justify-center"
              measurementUnit={measurementUnit}
              quantityInBasket={quantity}
              salesEntityQuantity={salesEntityQuantity}
              lineThrough={isRemoved}
              variant="positive"
            />
          </div>
          <div className={`flex flex-col items-end justify-around ${columnClassName}`}>
            <OrderItemPriceTotals
              itemTotal={totalAmount}
              unitPrice={unitPrice}
              measurementUnit={measurementUnit}
              isRemoved={isRemoved}
            />
          </div>
          {!isAvailable && !isRemoved && (
            <div className="absolute top-[20px] -left-4.5">
              {unavailabilityReason ? (
                <Tooltip tooltipClassName="w-20" tooltipText={unavailabilityReason}>
                  <MuiIcon Icon={ExclamationCircleIcon} className="w-4.5 text-warningDefault" />
                </Tooltip>
              ) : (
                <MuiIcon Icon={ExclamationCircleIcon} className="w-4.5 text-warningDefault" />
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};
