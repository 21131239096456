import { SupplierPortalAppService } from "src/typegens";

import { IOrderItemQuantityChangeConfig } from "../models/OrderItemQuantityChange";

export const orderItemQuantityChangeConfig: Readonly<IOrderItemQuantityChangeConfig> = {
  // Leave empty to disable consolidation or provide a list of *all* meta fields
  // that should differentiate the changes for consolidation
  consolidationComparators: ["orderId", "orderItemId"],
  apiResponseMapper: (change, apiResponse) => ({
    ...change,
    status: "success",
    meta: {
      ...change.meta,
      newOrderVersionId: apiResponse.newVersion,
    },
  }),
  apiRequestCall: async (change, signal) =>
    SupplierPortalAppService.updateLineItemQuantity(
      {
        s2SOrderId: change.meta.orderId,
        requestBody: {
          itemId: change.meta.orderItemId,
          quantity: change.newValue.quantity,
          entityQuantity: change.newValue.entityQuantity,
          version: change.meta.oldOrderVersionId,
        },
      },
      { signal }
    ),
};
