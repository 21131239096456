import { useMemo } from "react";

import { useUserScopes } from "src/context/UserScopesContext";

type UseUserRoles = {
  isSupervisor: boolean;
  isAdmin: boolean;
  isSupplier: boolean;
};

export const useUserRoles = (): UseUserRoles => {
  const { hasScopes, doesNotHaveScopes } = useUserScopes();

  const isSupplier = useMemo(
    () =>
      hasScopes("read:supplierOrders", "write:supplierOrder", "read:suppliers") &&
      doesNotHaveScopes("write:suppliers"),
    [doesNotHaveScopes, hasScopes]
  );
  const isSupervisor = useMemo(
    () =>
      hasScopes("read:supplierOrders") &&
      doesNotHaveScopes("write:supplierOrder", "read:suppliers", "write:suppliers"),
    [doesNotHaveScopes, hasScopes]
  );
  const isAdmin = useMemo(() => hasScopes("read:suppliers", "write:suppliers"), [hasScopes]);

  return {
    isSupplier,
    isSupervisor,
    isAdmin,
  };
};
