import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { setAppLanguage } from "./config/i18n";
import { ApplicationSwitch } from "./context/ApplicationSwitch";
import AppProviders from "./context/Providers";

// Do not destruct, so that browser `process` polyfill works properly. This is crucial for Storybook to work properly.
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as string;
const VITE_GIT_HASH = import.meta.env.VITE_GIT_HASH as string;

Sentry.init({
  integrations: [new BrowserTracing()],
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENVIRONMENT,
  release: VITE_GIT_HASH,
});

// setup internationalization
// setup autogenerated API service

const App = () => {
  setAppLanguage();

  return (
    <AppProviders>
      <ApplicationSwitch />
    </AppProviders>
  );
};

export default App;
