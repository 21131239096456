import { FileSelect } from "@web/ui";

import { FileUploadError } from "../../hooks";

interface Props {
  render: (triggerFileSelection: () => void) => void;
  fileSelectSuccess: (file: File) => void;
  fileSelectError: (file: File, error: FileUploadError) => void;
  doesUploadExist?: (file: File) => boolean;
  acceptedFileExtensions?: string[];
  maxSizeInBytes?: number;
}

export const FileSelectController: React.FC<Props> = ({
  render,
  acceptedFileExtensions,
  maxSizeInBytes = Infinity,
  doesUploadExist,
  fileSelectSuccess,
  fileSelectError,
}) => {
  const setFileSelectionError = (errorType: FileUploadError, file: File) => {
    fileSelectError(file, errorType);
  };

  const handleFileSelection = (file: File) => {
    if (doesUploadExist && doesUploadExist(file)) {
      return setFileSelectionError("FILE_ALREADY_EXISTS", file);
    }

    if (
      acceptedFileExtensions &&
      !acceptedFileExtensions.find((extension) => file.name.toLowerCase().endsWith(extension))
    ) {
      return setFileSelectionError("FILE_EXTENSION_UNSUPPORTED", file);
    }

    if (file.size > maxSizeInBytes) {
      return setFileSelectionError("FILE_SIZE_EXCEEDED", file);
    }

    fileSelectSuccess(file);
  };

  return (
    <FileSelect
      render={render}
      onFileSelection={handleFileSelection}
      accept={(acceptedFileExtensions || []).join(",")}
    />
  );
};
