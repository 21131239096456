import { format } from "date-fns-tz";
import { useCallback, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { getBadgeSettingsFromOrderType } from "@web/common";
import {
  Avatar,
  Badge,
  DropdownItem,
  ICell,
  IRow,
  Table,
  Tooltip,
  renderLongStringWithOptionalTooltip,
} from "@web/ui";
import { formatDate, formatInitials, formatMoney, formatShortString, formatTime } from "@web/utils";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { ColumnName, SupplierOrder, SupplierOrderService, SupplierOrdersSortBy } from "src/domain";

type Props = {
  items?: SupplierOrder[];
  isLoading?: boolean;
  getHeaderCellDropdownOptions: (args: {
    sortBy?: SupplierOrdersSortBy;
    filterBy?: ColumnName;
  }) => Array<DropdownItem> | undefined;
};

export const OrdersTableUI = ({ items = [], getHeaderCellDropdownOptions, isLoading }: Props) => {
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (order: SupplierOrder) => {
      navigate(generatePath(RoutesConfig.orderDetails, { [RoutesParams.ORDER_ID]: order.orderId }));
    },
    [navigate]
  );

  const allItemsAreDefault = useMemo(
    () => items.every((item) => SupplierOrderService.isDefaultOrderType(item)),
    [items]
  );
  const canShowOrderType = !allItemsAreDefault;

  const formatAssigneeDetails = (order: SupplierOrder) =>
    `${order.assignee?.name || ""} ${order.assignee?.email || ""}`;

  const getAssignee = useCallback((order: SupplierOrder): ICell => {
    if (!order.assignee) {
      return {
        type: "title",
        data: "Unassigned",
      };
    }
    return {
      type: "title",
      data: (
        <Tooltip tooltipText={formatAssigneeDetails(order)}>
          <Avatar
            text={formatInitials(order.assignee.name)}
            data-testid="ordersTable-assigneeAvatar"
            sizeClass="6"
          />
        </Tooltip>
      ),
    };
  }, []);

  return (
    <Table
      testId="ordersTable"
      headings={[
        {
          text: "Order Name",
          testId: "ORDER_NAME",
        },
        {
          text: "Customer Name",
          dropdownOptions: getHeaderCellDropdownOptions({
            sortBy: "CUSTOMER_NAME",
            filterBy: "CUSTOMER_NAME",
          }),
          testId: "CUSTOMER_NAME",
        },
        {
          text: "Vessel",
          dropdownOptions: getHeaderCellDropdownOptions({
            sortBy: "VESSEL_NAME",
            filterBy: "VESSEL_NAME",
          }),
          testId: "VESSEL_NAME",
        },
        {
          text: "Delivery Port",
          dropdownOptions: getHeaderCellDropdownOptions({
            sortBy: "DELIVERY_PORT",
            filterBy: "DELIVERY_PORT",
          }),
          testId: "DELIVERY_PORT",
        },
        {
          text: "Delivery Date",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "DELIVERY_DATE" }),
        },
        {
          text: "Customer PO",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "CUSTOMER_PO" }),
        },
        {
          text: "Total Value",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "TOTAL_VALUE" }),
          textEnd: true,
        },
        {
          text: "Currency",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "CURRENCY" }),
        },
        canShowOrderType
          ? {
              text: "Order Type",
            }
          : undefined,
        {
          text: "Order Date",
          dropdownOptions: getHeaderCellDropdownOptions({ sortBy: "CREATED_DATE" }),
        },
        {
          text: "Assignee",
        },
      ]}
      isLoading={isLoading}
      rows={useMemo(
        () =>
          items.map(
            (order: SupplierOrder): IRow => ({
              isUnseen: SupplierOrderService.isOrderUnseen(order),
              hasAttachment: SupplierOrderService.hasOrderAttachments(order),
              onClick: () => handleRowClick(order),
              data: [
                {
                  type: "title",
                  data: renderLongStringWithOptionalTooltip(
                    order.subject || "",
                    24,
                    formatShortString
                  ),
                },
                {
                  type: "title",
                  data: order.customerName,
                },
                {
                  type: "title",
                  data: order.vessel.name,
                },
                {
                  type: "twoLines",
                  data: {
                    firstLine: order.port.name,
                    secondLine: order.port.locationCode,
                  },
                },
                {
                  type: "twoLines",
                  data: {
                    firstLine: formatDate(order.deliveryDate),
                    secondLine: formatTime(order.deliveryDate),
                  },
                },
                {
                  type: "title",
                  data: order.customerOrderId,
                },
                {
                  type: "numeric",
                  data: formatMoney(order.totalGrossAmount, true),
                },
                {
                  type: "title",
                  data: order.totalGrossAmount.currencyCode,
                },
                canShowOrderType
                  ? {
                      type: "statusBadge",
                      data: !SupplierOrderService.isDefaultOrderType(order) ? (
                        <Badge
                          {...getBadgeSettingsFromOrderType({
                            orderType: order.orderType,
                            text: order.catalogName,
                          })}
                          size="s"
                        />
                      ) : (
                        <></>
                      ),
                    }
                  : undefined,
                {
                  type: "twoLines",
                  data: {
                    firstLine: format(order.createdAt, "dd MMM yyyy"),
                    secondLine: format(order.createdAt, "HH:mm"),
                  },
                },
                getAssignee(order),
              ],
            })
          ),
        [items, canShowOrderType, getAssignee, handleRowClick]
      )}
      emptyState={{
        title: "We looked everywhere..",
        subtitle: "There aren’t any orders.",
      }}
    />
  );
};
