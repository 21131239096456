import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { useState } from "react";

import { IconButton, LEGACY_Input } from "@web/ui";

interface Props {
  handleSearch: (phrase: string) => void;
}

export const SearchSkuInput: React.FC<Props> = ({ handleSearch }) => {
  const [input, setInput] = useState("");

  const doSearch = () => {
    handleSearch(input);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  return (
    <div className="flex" data-testid="searchSkuInput">
      <LEGACY_Input
        placeholder="Search by Name, IMPA or Item ID"
        value={input}
        onInputChange={setInput}
        onKeyDown={handleKeyDown}
        className="flex-1 mr-2"
      />
      <IconButton
        size="large"
        variant="primary"
        shape="square"
        label="Search"
        onClick={doSearch}
        Icon={SearchOutlined}
      />
    </div>
  );
};
