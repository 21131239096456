import { useEffect, useState } from "react";

import { SupplierOrder, useOrderAcknowledgeSeenMutation } from "src/domain";
import { useUserRoles } from "src/hooks";

type Props = {
  order: SupplierOrder;
  onAcknowledgeSuccess: () => void;
  onAcknowledgeNotNeeded: () => void;
};

export const AcknowledgeOrderSeen = ({
  order,
  onAcknowledgeSuccess,
  onAcknowledgeNotNeeded,
}: Props) => {
  const { isSupplier } = useUserRoles();

  const [wasAcknowledged, setWasAcknowledged] = useState<boolean>(false);

  const canAcknowledge = !order.seen && isSupplier;

  const acknowledgeOrderSeenMutation = useOrderAcknowledgeSeenMutation({
    onSuccess: () => {
      onAcknowledgeSuccess();
      setWasAcknowledged(true);
    },
  });

  useEffect(() => {
    if (wasAcknowledged || acknowledgeOrderSeenMutation.isPending) {
      return;
    }

    if (canAcknowledge) {
      acknowledgeOrderSeenMutation.mutate({ s2SOrderId: order.orderId });
      return;
    }

    onAcknowledgeNotNeeded();
  }, [
    wasAcknowledged,
    acknowledgeOrderSeenMutation.isPending,
    acknowledgeOrderSeenMutation,
    onAcknowledgeNotNeeded,
    canAcknowledge,
    order.orderId,
  ]);

  return null;
};
