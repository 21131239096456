import UserCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import DotsVerticalIcon from "@mui/icons-material/MoreVert";
import classnames from "classnames";

import { OptionType } from "@web/common";
import {
  Dropdown,
  DropdownItem,
  IconButton,
  Label,
  LoadingInline,
  MuiIcon,
  Paragraph,
  Select,
  Tooltip,
} from "@web/ui";
import { LEGACY_formatDateTime, formatFileSize } from "@web/utils";

interface Props {
  fileName: string;
  fileSizeInBytes: number;
  categories: OptionType[];
  onCategoryChange: (value?: OptionType) => void;
  chosenCategory?: OptionType;
  uploadedAt?: Date;
  uploader?: string;
  isRemoved?: boolean;
  isLoading?: boolean;
  loadingPercent?: number;
  actions?: DropdownItem[];
  className?: string;
  testId?: string;
}

export const AttachmentUI: React.FC<Props> = ({
  fileName,
  uploadedAt,
  uploader,
  fileSizeInBytes,
  categories,
  chosenCategory,
  onCategoryChange,
  isRemoved = false,
  isLoading = false,
  loadingPercent = 0,
  actions = [],
  className = "",
  testId = "",
}) => {
  const hasUploadMetadata = !!(uploader && uploadedAt);

  return (
    <div
      className={classnames(
        "bg-neutral_0 rounded-lg pl-4 pr-2 py-2 shadow-sm border-1 relative",
        isRemoved ? "bg-neutral_200" : "",
        className
      )}
      data-testid={testId}
    >
      <div className="flex justify-between">
        <div className="flex-1 mr-2 overflow-x-hidden">
          <Label
            size="200"
            className={`block truncate max-w-full ${isRemoved ? "line-through" : ""}`}
            title={fileName}
            data-testid={testId ? `${testId}_fileName` : ""}
          >
            {fileName}
          </Label>
          {hasUploadMetadata && (
            <Tooltip tooltipText={`Uploaded by ${uploader}`}>
              <Label
                size="300"
                color="text-textIcon-blackSecondary"
                className="flex items-center mt-[1px] font-normal"
                data-testid={testId ? `${testId}_uploadInfo` : ""}
              >
                <MuiIcon Icon={UserCircleIcon} className="flex-none w-3 mr-1" />
                <span className="block truncate max-w-full">
                  Uploaded on {LEGACY_formatDateTime(uploadedAt)}
                </span>
              </Label>
            </Tooltip>
          )}
        </div>
        <div className="flex-none flex justify-between">
          <div className="text-center w-[100px] ml-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              {formatFileSize(fileSizeInBytes)}
            </Paragraph>
            {isLoading && (
              <Paragraph size="200" className="flex justify-center items-center">
                {loadingPercent > 0 && <span className="block mr-1">{loadingPercent}%</span>}
                <LoadingInline size="4" />
              </Paragraph>
            )}
          </div>
          <div className="w-[130px] ml-2">
            <Select
              placeholder="Category"
              value={chosenCategory}
              options={categories}
              onChange={onCategoryChange}
              dropdownWidth="w-auto"
              dropdownHPosition="left-auto right-0"
              disabled={isRemoved}
              testId={testId ? `${testId}_categories` : undefined}
            />
          </div>
          <div className="w-[40px] ml-2">
            {actions.length > 0 && (
              <Dropdown
                hAlignTo="end"
                vAlignTo="bottom"
                items={actions}
                data-testid={testId ? `${testId}_actions` : undefined}
              >
                <IconButton
                  size="large"
                  variant="secondary"
                  shape="square"
                  label="File actions"
                  Icon={DotsVerticalIcon}
                  data-testid={testId ? `${testId}_actionsToggle` : undefined}
                  isControlled
                />
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
