import { useCallback, useMemo, useState } from "react";

import { Loading } from "@web/ui";

import {
  SupplierOrderChanges,
  SupplierOrderService,
  SupplierUiOrderItem,
  useOrderByIdQuery,
  useOrderByIdQueryHelpers,
  useOrderChangesByIdQuery,
} from "src/domain";

import { AcknowledgeOrderSeen } from "../AcknowledgeOrderSeen";
import { OrderDetailsComponent } from "../OrderDetailsComponent";

interface Props {
  orderId: string;
}

export const OrderDetailsQuery = ({ orderId }: Props) => {
  const [isOrderAcknowledging, setIsOrderAcknowledging] = useState<boolean>(true);

  const oldestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "OLDEST",
  });
  const {
    isPending: isOldestOrderPending,
    isFetching: isOldestOrderFetching,
    isSuccess: isOldestOrderSuccess,
    data: rawOldestOrderData,
  } = oldestOrderQuery;
  const { invalidate: invalidateOldestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "OLDEST",
  });

  const latestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
  });
  const {
    isPending: isLatestOrderPending,
    isFetching: isLatestOrderFetching,
    isSuccess: isLatestOrderSuccess,
    data: rawLatestOrderData,
  } = latestOrderQuery;
  const { invalidate: invalidateLatestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "LATEST",
  });

  const { query: orderChangesQuery, invalidate: invalidateOrderChangesQuery } =
    useOrderChangesByIdQuery(
      { orderId, orderStatus: rawLatestOrderData?.status },
      { enabled: !!rawLatestOrderData?.status }
    );
  const {
    isPending: areOrderChangesPending,
    isFetching: areOrderChangesFetching,
    isSuccess: isOrderChangesSuccess,
    data: rawOrderChangesData,
  } = orderChangesQuery;

  const invalidateQueries = useCallback(() => {
    invalidateOldestOrderQuery();
    invalidateLatestOrderQuery();
    invalidateOrderChangesQuery();
  }, [invalidateLatestOrderQuery, invalidateOldestOrderQuery, invalidateOrderChangesQuery]);

  const isAnyQueryPendingOrFetching =
    isOldestOrderPending ||
    isOldestOrderFetching ||
    isLatestOrderPending ||
    isLatestOrderFetching ||
    areOrderChangesPending ||
    areOrderChangesFetching;

  const orderUiItems: SupplierUiOrderItem[] = useMemo(
    () =>
      rawLatestOrderData?.items
        ? rawLatestOrderData.items.map((item) =>
            SupplierOrderService.convertOrderItemDataToUiModel(item, item.lineNumber)
          )
        : [],
    [rawLatestOrderData?.items]
  );

  const orderChanges: SupplierOrderChanges = useMemo(
    () =>
      SupplierOrderService.convertToSupplierOrderChanges(orderUiItems, rawOrderChangesData || []),
    [orderUiItems, rawOrderChangesData]
  );

  const onAcknowledgeSuccess = useCallback(() => {
    invalidateQueries();
    setTimeout(() => setIsOrderAcknowledging(false), 0);
  }, [invalidateQueries]);

  const onAcknowledgeNotNeeded = useCallback(() => {
    setIsOrderAcknowledging(false);
  }, []);

  if (isAnyQueryPendingOrFetching) {
    return <Loading />;
  }

  if (isOldestOrderSuccess && isLatestOrderSuccess && isOrderChangesSuccess) {
    const isOrderEdited = SupplierOrderService.isOrderEdited(
      rawLatestOrderData,
      rawOldestOrderData.version,
      rawLatestOrderData.version
    );
    return (
      <>
        <AcknowledgeOrderSeen
          order={rawLatestOrderData}
          onAcknowledgeSuccess={onAcknowledgeSuccess}
          onAcknowledgeNotNeeded={onAcknowledgeNotNeeded}
        />
        {isOrderAcknowledging ? (
          <Loading />
        ) : (
          <OrderDetailsComponent
            isOrderEdited={isOrderEdited}
            order={rawLatestOrderData}
            orderChanges={orderChanges}
            orderUiItems={orderUiItems}
            invalidateOrderQueries={invalidateQueries}
          />
        )}
      </>
    );
  }

  return <div>undefined state</div>;
};
