import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import {
  Auth0WithRedirectProvider,
  Environment,
  FeatureFlagsManagementProvider,
} from "@web/common";
import { CustomerSupportWidget } from "@web/ui";

import { BlockUI } from "src/components";
import { ErrorBoundaryRenderer } from "src/context/ErrorBoundary";
import { OrderEditsSyncProvider } from "src/domain";

import { ApiHeadersSetup } from "./ApiHeadersSetup";
import { AuthCheckpoint } from "./AuthCheckpoint";
import { ModalProvider } from "./ModalContext";
import { PendoInitializer } from "./PendoInitializer";
import { queryClient } from "./QueryClientCreator";
import { SystemMessagesProvider } from "./SystemMessages";
import { UnleashContextSetup } from "./UnleashContextSetup";
import { UserScope, UserScopesContextProvider } from "./UserScopesContext";

// Do not destruct, so that browser `process` polyfill works properly. This is crucial for Storybook to work properly.
const VITE_AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
const VITE_AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN as string;
const VITE_AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE as string;
const VITE_UNLEASH_PROXY_URL = import.meta.env.VITE_UNLEASH_PROXY_URL as string;
const VITE_UNLEASH_PROXY_CLIENT_KEY = import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY as string;
const VITE_UNLEASH_APP_ENVIRONMENT = import.meta.env.VITE_UNLEASH_APP_ENVIRONMENT as Environment;

const UNLEASH_APP_NAME = "supplier";
const UNLEASH_REFRESH_INTERVAL = 60;

const scopes: Record<UserScope, undefined> = {
  "read:suppliers": undefined,
  "write:suppliers": undefined,
  "read:supplierOrders": undefined,
  "write:supplierOrder": undefined,
};

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <Router>
      <CustomerSupportWidget />
      <UserScopesContextProvider>
        <ErrorBoundaryRenderer>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <FeatureFlagsManagementProvider
              environment={VITE_UNLEASH_APP_ENVIRONMENT}
              unleashProxyUrl={VITE_UNLEASH_PROXY_URL}
              unleashAppName={UNLEASH_APP_NAME}
              unleashProxyClientKey={VITE_UNLEASH_PROXY_CLIENT_KEY}
              unleashRefreshInterval={UNLEASH_REFRESH_INTERVAL}
            >
              <ApiHeadersSetup />
              <Auth0WithRedirectProvider
                domain={VITE_AUTH0_DOMAIN}
                clientId={VITE_AUTH0_CLIENT_ID}
                audience={VITE_AUTH0_AUDIENCE}
                redirectUri={window.location.origin}
                scope={Object.keys(scopes).join(" ")}
                useRefreshTokens
              >
                <SystemMessagesProvider>
                  <BlockUI />
                  <OrderEditsSyncProvider>
                    <ModalProvider>
                      <AuthCheckpoint>
                        <>
                          <PendoInitializer />
                          <UnleashContextSetup />
                          {children}
                        </>
                      </AuthCheckpoint>
                    </ModalProvider>
                  </OrderEditsSyncProvider>
                </SystemMessagesProvider>
              </Auth0WithRedirectProvider>
            </FeatureFlagsManagementProvider>
          </QueryClientProvider>
        </ErrorBoundaryRenderer>
      </UserScopesContextProvider>
    </Router>
  );
};

export default AppProviders;
