import ChevronLeftIcon from "@mui/icons-material/ChevronLeftOutlined";

import { IconButton, Label } from "@web/ui";

interface Props {
  backActionTitle: string;
  backActionCallback: () => void;
}

export const BackActionHeader: React.FC<Props> = ({ backActionTitle, backActionCallback }) => {
  return (
    <div className={`flex shadow w-full h-8 bg-neutral_0`}>
      <div className="w-[1024px] flex items-center px-8 m-auto">
        <IconButton
          size="large"
          variant="secondary"
          shape="circle"
          label="Go back"
          onClick={backActionCallback}
          Icon={ChevronLeftIcon}
        />
        <div className="flex-col" onClick={backActionCallback}>
          <div className="flex align-start pl-4.5">
            <Label size="200" color="text-textIcon-blackPrimary">
              {backActionTitle}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};
