import { OAuthTokenOptions } from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import { PropsWithChildren, useEffect, useState } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { useAuth0WithRedirect } from "@web/common";
import { Loading } from "@web/ui";

import { UnauthenticatedApp } from "src/components";
import { OpenAPI } from "src/typegens";

import { UserScope, useUserScopes } from "./UserScopesContext";

type AuthState = "loading" | "authenticated" | "notAuthenticated";

const getUserScopes = (token: string) => {
  try {
    const decoded: OAuthTokenOptions = jwtDecode(token);
    return decoded.scope.split(" ") as UserScope[];
  } catch (err) {
    console.warn(err);
    return [];
  }
};

export const AuthCheckpoint = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0WithRedirect();
  const [state, setState] = useState<AuthState>("loading");
  const { setUserScopes } = useUserScopes();

  useEffect(() => {
    if (isAuthenticated) {
      const handleToken = async () => {
        const token = await getAccessTokenSilently();
        OpenAPI.TOKEN = getAccessTokenSilently;
        AttachmentsOpenAPI.TOKEN = getAccessTokenSilently;
        const scopes = getUserScopes(token);
        setUserScopes(scopes);
        setState("authenticated");
      };
      handleToken().catch(console.error);
    } else {
      if (isLoading) {
        setState("loading");
      } else {
        setState("notAuthenticated");
      }
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, setState, setUserScopes]);

  if (state === "loading") {
    return <Loading />;
  }

  if (state === "notAuthenticated") {
    return <UnauthenticatedApp />;
  }

  return <>{children}</>;
};
