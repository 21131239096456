import classnames from "classnames";
import { useMemo } from "react";

import { Label, Paragraph } from "@web/ui";

export type SpacingVariant = "tight" | "normal";

interface Props {
  isRemoved?: boolean;
  isAvailable?: boolean;
  renderAdditionalData?: (columnWidth: string) => React.ReactNode;
  onItemClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onNameClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  impaCode?: string;
  itemId?: string;
  itemLineNumber: number;
  showItemLineNumber?: boolean;
  keepLeftMargin?: boolean;
  itemName: string;
  testId?: string;
  spacing?: SpacingVariant;
}

const getNameColorClassName = (
  isItemClickable: boolean,
  isNameClickable: boolean,
  isRemoved: boolean
) => {
  if (isItemClickable) {
    return isRemoved
      ? "text-textIcon-blackSecondary group-hover:text-textIcon-blackSecondary"
      : "text-textIcon-blackPrimary group-hover:text-primaryDefault";
  } else if (isNameClickable) {
    return isRemoved
      ? "text-textIcon-blackSecondary hover:text-textIcon-blackSecondary"
      : "text-textIcon-blackPrimary hover:text-primaryDefault";
  }
  return isRemoved ? "text-textIcon-blackSecondary" : "text-textIcon-blackPrimary";
};

const getSpacingClassName = (spacing: SpacingVariant) => (spacing === "tight" ? "mt-1" : "mt-2");

export const OrderItem = ({
  isRemoved = false,
  isAvailable = false,
  renderAdditionalData,
  onItemClick,
  onNameClick,
  impaCode,
  itemId,
  itemLineNumber,
  showItemLineNumber,
  keepLeftMargin = false,
  itemName,
  testId = "",
  spacing = "normal",
  ...rest
}: Props) => {
  const hasSecondLineDetails = !!(impaCode || itemId);
  const isItemClickable = !!onItemClick;
  const isNameClickable = !!onNameClick;
  const isLineNumberVisible = showItemLineNumber;

  const nameColorClassName = useMemo(
    () => getNameColorClassName(isItemClickable, isNameClickable, isRemoved),
    [isItemClickable, isNameClickable, isRemoved]
  );

  const spacingClassName = useMemo(() => getSpacingClassName(spacing), [spacing]);

  return (
    <div
      className={classnames("flex relative justify-between items-center", spacingClassName, {
        "group cursor-pointer": isItemClickable,
      })}
      tabIndex={isItemClickable ? 0 : -1}
      onClick={onItemClick}
      data-testid={testId}
      {...rest}
    >
      {isLineNumberVisible && (
        <div className="flex-none w-6 text-center" data-testid="lineNumber">
          <Label size="200">{!!itemLineNumber && itemLineNumber}</Label>
        </div>
      )}
      <div
        className={classnames(
          "flex-1 rounded-lg px-4 py-2 shadow-sm border-1",
          {
            "ml-6": !showItemLineNumber && keepLeftMargin,
            "border-warningDefault": !isAvailable && !isRemoved,
          },
          isRemoved ? "bg-neutral_200" : "bg-neutral_0"
        )}
      >
        <div className="flex justify-between">
          <div className="flex-1 flex flex-col items-start justify-center mr-4 overflow-x-hidden max-w-sm">
            <Label
              size="200"
              className={classnames(nameColorClassName, "truncate max-w-full", {
                "cursor-pointer": isItemClickable || isNameClickable,
              })}
              tabIndex={isNameClickable ? 0 : -1}
              onClick={onNameClick}
            >
              {itemName}
            </Label>
            {hasSecondLineDetails && (
              <div className="flex mt-[1px]">
                {!!impaCode && (
                  <Paragraph size="300" color="text-textIcon-blackSecondary" className="mr-4">
                    IMPA: {impaCode}
                  </Paragraph>
                )}
                {!!itemId && (
                  <Paragraph size="300" color="text-textIcon-blackSecondary" className="mr-4">
                    Item ID: {itemId}
                  </Paragraph>
                )}
              </div>
            )}
          </div>
          {!!renderAdditionalData && renderAdditionalData("w-[9rem]")}
        </div>
      </div>
    </div>
  );
};
