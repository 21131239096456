import { IntersectionMonitor } from "@web/common";
import { Label, Loading } from "@web/ui";

import { useSearchProducts } from "../hooks";
import { SupplierOrderItemForm } from "../models";
import { SupplierOrderService } from "../services";
import { OrderItemDetails } from "./OrderItemDetails";

interface Props {
  searchPhrase: string;
  orderId: string;
  onItemClick(item: SupplierOrderItemForm): void;
  originalItem?: SupplierOrderItemForm;
  itemActionType: "add" | "replace";
  excludedItemIds?: string[];
}

export const SearchResults = ({
  searchPhrase,
  orderId,
  onItemClick,
  originalItem,
  itemActionType,
  excludedItemIds,
}: Props) => {
  const isReplacementFlow = itemActionType === "replace" && originalItem !== undefined;
  const isQueryEnabled = !!searchPhrase;
  const searchQuery = useSearchProducts(
    { s2SOrderId: orderId, searchQuery: searchPhrase },
    {
      enabled: isQueryEnabled,
    }
  );

  if (!isQueryEnabled) {
    return null;
  }

  if (searchQuery.isPending || searchQuery.isFetching) {
    return (
      <div className="flex mt-2">
        <Loading />
      </div>
    );
  }

  if (searchQuery.isError) {
    return (
      <div className="flex">
        <Label size="100">Error here</Label>
      </div>
    );
  }

  let results;

  if (isReplacementFlow) {
    results = (searchQuery.data?.pages.flatMap((page) => page.items) || []).filter(
      (item) => item.id !== originalItem.id && !(excludedItemIds || []).includes(item.id)
    );
  } else {
    results = (searchQuery.data?.pages.flatMap((page) => page.items) || []).filter(
      (item) => !(excludedItemIds || []).includes(item.id)
    );
  }

  if (results.length === 0) {
    return <div className="mt-4 text-center">No results for `{searchPhrase}`</div>;
  }

  return (
    <div className="flex flex-col" data-testid="searchResults">
      <Label size="300" color="text-textIcon-blackSecondary" className="uppercase">
        Search results
      </Label>
      <div className="max-h-114 overflow-x-hidden ">
        {results.map((sku) => {
          const item = isReplacementFlow
            ? SupplierOrderService.convertSkuToReplacementOrderItem(originalItem, sku)
            : SupplierOrderService.convertSkuToAddOrderItem(sku);

          const uiItem = SupplierOrderService.convertOrderItemDataToUiModel(item, item.lineNumber);

          return (
            <OrderItemDetails
              key={sku.id}
              orderItem={uiItem}
              isAvailable={true}
              onItemClick={() => onItemClick(item)}
              testId={itemActionType === "replace" ? "orderItemReplace" : "orderItemAdd"}
            />
          );
        })}

        {searchQuery.hasNextPage && (
          <IntersectionMonitor onEnter={searchQuery.fetchNextPage}>
            <div className="h-10 max-w-6xl">{searchQuery.isFetchingNextPage && <Loading />}</div>
          </IntersectionMonitor>
        )}
      </div>
    </div>
  );
};
