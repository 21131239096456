import { PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";

import { useUserRoles } from "src/hooks";

import { ErrorBoundary } from "./ErrorBoundary";

export const ErrorBoundaryRenderer = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth0WithRedirect();
  const { isAdmin } = useUserRoles();

  return (
    <ErrorBoundary
      isAdminUser={isAdmin}
      logout={logout}
      navigate={navigate}
      pathname={location.pathname}
    >
      {children}
    </ErrorBoundary>
  );
};
