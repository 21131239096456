import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { OrderChange, SupplierPortalAppService, SupplierPortalOrder } from "src/typegens";

const QUERY_KEY = "getOrderChangesById";

export const useOrderChangesByIdQuery = (
  {
    orderId,
    orderStatus,
  }: {
    orderId: string;
    orderStatus?: SupplierPortalOrder["status"];
  },
  options = {}
) => {
  const queryClient = useQueryClient();

  const query = useQuery<OrderChange[]>({
    queryKey: [QUERY_KEY, orderId],
    queryFn: () => {
      if (
        orderStatus !== "CONFIRMED" &&
        orderStatus !== "INCOMING" &&
        orderStatus !== "DELIVERED"
      ) {
        return [];
      }

      return SupplierPortalAppService.getChanges({
        s2SOrderId: orderId,
      });
    },
    ...options,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY, orderId],
      }),
    [orderId, queryClient]
  );

  return { query, invalidate };
};
