import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { AmountField, LEGACY_Input, Label } from "@web/ui";

import { SupplierOrderAmountDiscountForm, SupplierOrderAmountDiscountSchema } from "../../models";
import { PriceModifierModal } from "../PriceModifierModal";

interface Props {
  currencyCode: string;
  currentTotalAmount: number;
  cancel: () => void;
  submit: (discount: SupplierOrderAmountDiscountForm) => Promise<void>;
  isSubmitting?: boolean;
  isSubmittingExternallyDisabled?: boolean;
  onSetIsDirty?: (isDirty: boolean) => void;
  onSubmitSuccess?: () => void;
}

export const DiscountModalForm: React.FC<Props> = ({
  currencyCode,
  currentTotalAmount,
  submit,
  isSubmitting = false,
  isSubmittingExternallyDisabled = false,
  cancel,
  onSetIsDirty,
  onSubmitSuccess,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const discountForm = useForm<SupplierOrderAmountDiscountForm>({
    mode: "onChange",
    resolver: zodResolver(SupplierOrderAmountDiscountSchema),
    defaultValues: {
      id: "",
      name: "",
      amount: {
        // Cast type to be able to set an empty input field by default
        amount: "" as unknown as number,
        currencyCode,
      },
    },
  });

  const { control, formState } = discountForm;

  useEffect(() => onSetIsDirty && onSetIsDirty(isDirty), [onSetIsDirty, isDirty]);

  return (
    <FormProvider {...discountForm}>
      <PriceModifierModal
        headerLabel="Add discount"
        currencyCode={currencyCode}
        currentTotalAmount={currentTotalAmount}
        submit={submit}
        cancel={cancel}
        isSubmitting={isSubmitting}
        isSubmittingExternallyDisabled={isSubmittingExternallyDisabled}
        onSetIsDirty={setIsDirty}
        onSubmitSuccess={onSubmitSuccess}
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-5">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0"
                  label="Discount name"
                  errorMessage={formState?.errors.name?.message}
                  placeholder="e.g. General discount"
                  testId="discountModalForm_field_name"
                  {...field}
                />
              )}
            />
          </div>
          <div className="col-span-3">
            <Controller
              name="amount.amount"
              control={control}
              render={({
                field: {
                  // We need to extract `ref` from the props so we don't get errors on runtime
                  ref,
                  ...fieldRest
                },
              }) => (
                <AmountField
                  className="pt-0"
                  InputComponent={LEGACY_Input}
                  label="Amount"
                  errorMessage={formState?.errors.amount?.amount?.message}
                  placeholder="Discount"
                  testId="discountModalForm_field_amount"
                  withFractions={true}
                  {...fieldRest}
                />
              )}
            />
          </div>
          <div className="col-span-4">
            <Label size="200" className="block mt-6">
              {currencyCode}
            </Label>
          </div>
        </div>
      </PriceModifierModal>
    </FormProvider>
  );
};
