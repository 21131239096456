import { useState } from "react";

import { Modal } from "@web/ui";

import {
  OrderItemDetails,
  OrderItemDetailsModal,
  SpacingVariant,
  SupplierUiOrderItem,
} from "src/domain";

interface Props {
  orderItem: SupplierUiOrderItem;
  isRemoved?: boolean;
  isAvailable: boolean;
  unavailabilityReason?: string;
  showLineNumber?: boolean;
  keepLeftMargin?: boolean;
  spacing?: SpacingVariant;
  testId?: string;
}

export const OrderItemDetailsController = ({
  isRemoved,
  isAvailable,
  unavailabilityReason,
  orderItem,
  showLineNumber,
  keepLeftMargin,
  spacing,
  testId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDetailsModal = () => setIsModalOpen(true);
  const closeDetailsModal = () => setIsModalOpen(false);

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeDetailsModal}>
        <OrderItemDetailsModal
          lineNumber={orderItem.lineNumber}
          name={orderItem.name}
          impaCode={orderItem.impaCode || ""}
          itemId={orderItem.supplierIdentifier || ""}
          closeModal={closeDetailsModal}
        />
      </Modal>
      <OrderItemDetails
        orderItem={orderItem}
        onItemClick={openDetailsModal}
        isRemoved={isRemoved}
        isAvailable={isAvailable}
        unavailabilityReason={unavailabilityReason}
        showItemLineNumber={showLineNumber}
        keepLeftMargin={keepLeftMargin}
        spacing={spacing}
        testId={testId}
      />
    </>
  );
};
