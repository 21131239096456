import { useMemo, useState } from "react";

import { TotalUnitOfMeasure } from "@web/common";
import { Money } from "@web/models";
import { Label, Modal } from "@web/ui";

import {
  OrderItem,
  OrderItemDetailsModal,
  OrderItemPriceTotals,
  SupplierOrderService,
} from "src/domain";
import { OrderChange } from "src/typegens";

import { TinyPriceTotal } from "../TinyPriceTotal";
import { TinyTotalUnitOfMeasure } from "../TinyTotalUnitOfMeasure";

interface Props {
  orderChange: OrderChange;
  testId?: string;
}

export const OrderItemChangesController: React.FC<Props> = ({ orderChange, testId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lineNumber, item, previousItem } = orderChange;
  const {
    supplierIdentifier,
    impaCode,
    measurementUnit,
    name,
    quantity,
    entityQuantity,
    totalAmount,
  } = item;
  const {
    measurementUnit: previousMeasurementUnit,
    quantity: previousQuantity,
    entityQuantity: previousEntityQuantity,
    totalAmount: previousTotalAmount,
  } = previousItem || {};

  /**
   * TotalUnitOfMeasure
   */
  const salesEntityQuantity = useMemo(
    () => SupplierOrderService.getSalesEntityQuantity(item),
    [item]
  );
  const previousSalesEntityQuantity = useMemo(
    () => (previousItem ? SupplierOrderService.getSalesEntityQuantity(previousItem) : undefined),
    [previousItem]
  );

  /**
   * Unit total
   */
  const unitPrice: Money = useMemo(() => SupplierOrderService.getSingleItemPrice(item), [item]);
  const uiOrderItem = SupplierOrderService.convertOrderItemDataToUiModel(item, lineNumber);

  const haveUnitOrEntityQuantityChanged =
    previousMeasurementUnit !== measurementUnit || previousEntityQuantity !== entityQuantity;
  const hasTotalAmountChanged = previousTotalAmount?.amount !== totalAmount.amount;

  const openDetailsModal = () => setIsModalOpen(true);
  const closeDetailsModal = () => setIsModalOpen(false);

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeDetailsModal}>
        <OrderItemDetailsModal
          lineNumber={uiOrderItem.lineNumber}
          name={uiOrderItem.name}
          impaCode={uiOrderItem.impaCode || ""}
          itemId={uiOrderItem.supplierIdentifier || ""}
          closeModal={closeDetailsModal}
        />
      </Modal>
      <OrderItem
        impaCode={impaCode}
        itemId={supplierIdentifier}
        itemLineNumber={lineNumber}
        showItemLineNumber={true}
        itemName={name}
        onItemClick={openDetailsModal}
        isAvailable={true}
        testId={testId}
        renderAdditionalData={(columnClassName) => (
          <div className="flex-none grid grid-cols-2">
            <div className={`flex flex-col items-start justify-around pl-4 ${columnClassName}`}>
              {haveUnitOrEntityQuantityChanged && (
                <TinyTotalUnitOfMeasure
                  measurementUnit={previousMeasurementUnit || ""}
                  quantityInBasket={previousQuantity || 0}
                  salesEntityQuantity={previousSalesEntityQuantity || 0}
                  label="Initial"
                  lineThrough={true}
                />
              )}
              <TotalUnitOfMeasure
                className="justify-center"
                measurementUnit={measurementUnit}
                quantityInBasket={quantity}
                salesEntityQuantity={salesEntityQuantity}
                variant={haveUnitOrEntityQuantityChanged ? "positive" : undefined}
                label={haveUnitOrEntityQuantityChanged ? "Updated" : undefined}
                LabelComponent={haveUnitOrEntityQuantityChanged ? Label : undefined}
              />
            </div>
            <div className={`flex flex-col items-end justify-around ${columnClassName}`}>
              {hasTotalAmountChanged && previousTotalAmount && (
                <TinyPriceTotal lineThrough={true} itemTotal={previousTotalAmount} />
              )}
              <OrderItemPriceTotals
                itemTotal={totalAmount}
                unitPrice={unitPrice}
                measurementUnit={measurementUnit}
              />
            </div>
          </div>
        )}
      />
    </>
  );
};
