import { useCallback } from "react";

import { useUserScopesContext } from "./UserScopesContext";
import { UserScope } from "./models";

type UseUserScopes = {
  hasScopes: (...userScopes: UserScope[]) => boolean;
  doesNotHaveScopes: (...userScopes: UserScope[]) => boolean;
  setUserScopes: (scopes: UserScope[]) => void;
};

export const useUserScopes = (): UseUserScopes => {
  const [scope, dispatch] = useUserScopesContext();

  const hasScopes = useCallback(
    (...userScopes: UserScope[]) =>
      userScopes.every((userScope) => scope.indexOf(userScope) !== -1),
    [scope]
  );

  const doesNotHaveScopes = useCallback(
    (...userScopes: UserScope[]) =>
      userScopes.every((userScope) => scope.indexOf(userScope) === -1),
    [scope]
  );

  const setUserScopes = useCallback(
    (scopes: UserScope[]) => dispatch({ type: "setUserScopes", value: scopes }),
    [dispatch]
  );

  return {
    hasScopes,
    doesNotHaveScopes,
    setUserScopes,
  };
};
