import { SupplierInformationUpdateRequest, SupplierManagementService } from "src/typegens";

export const updateSupplier = async ({
  requestBody,
}: {
  requestBody: SupplierInformationUpdateRequest;
}) => {
  const result = await SupplierManagementService.updateSupplierInformation({
    requestBody,
  });
  return result;
};
