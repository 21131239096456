import { useEffect, useRef } from "react";

import { useAuth0WithRedirect } from "@web/common";

import { useSupplierInformationQuery } from "src/domain";
import { useUserRoles } from "src/hooks";

export const PendoInitializer = () => {
  const { isSupplier } = useUserRoles();
  const { user } = useAuth0WithRedirect();

  const { data: supplierInformation } = useSupplierInformationQuery({
    enabled: isSupplier,
  });

  const userEmail = user?.email;
  const userName = user?.name;

  const supplierId = supplierInformation?.supplierId;
  const supplierName = supplierInformation?.information?.vendor.vendorName;

  const pendoInitialized = useRef<boolean>(false);
  const isPendoInitialized = pendoInitialized.current === true;

  useEffect(() => {
    if (
      !isSupplier ||
      isPendoInitialized ||
      !userEmail ||
      !userName ||
      !supplierId ||
      !supplierName
    ) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: userEmail,
        email: userEmail,
        full_name: userName,
      },

      account: {
        id: supplierId,
        name: supplierName,
      },
    });
    pendoInitialized.current = true;
  }, [isPendoInitialized, isSupplier, supplierId, supplierName, userEmail, userName]);

  return null;
};
