import { DefaultError, useMutation } from "@tanstack/react-query";

import { SupplierInformationUpdateRequest, SupplierResponse } from "src/typegens";

import { updateSupplier } from "../api";

export const useUpdateSupplierMutation = (options = {}) => {
  return useMutation<
    SupplierResponse,
    DefaultError,
    { requestBody: SupplierInformationUpdateRequest }
  >({
    mutationKey: ["updateSupplier"],
    mutationFn: updateSupplier,
    ...options,
  });
};
