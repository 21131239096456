import { useReducer } from "react";

import { UserScopesState, UserScopesStateAction } from "./models";

export const defaultUserScopesState: UserScopesState = [];

const UserScopesReducer = (
  scope: UserScopesState,
  action: UserScopesStateAction
): UserScopesState => {
  switch (action.type) {
    case "setUserScopes":
      return action.value;
    default:
      return scope;
  }
};

export const useUserScopesReducer = () => useReducer(UserScopesReducer, defaultUserScopesState);
