import { z } from "zod";

import { ProductSkuSchema } from "./ProductSkuSchema";
import { SupplierOrderItemSchema } from "./SupplierOrderItemSchema";

export const SupplierOrderCatalogItemSchema = SupplierOrderItemSchema.omit({
  skuDetails: true,
}).extend({
  skuDetails: ProductSkuSchema,
});

export type SupplierOrderCatalogItemForm = z.infer<typeof SupplierOrderCatalogItemSchema>;
