import classnames from "classnames";

import { SupplierOrderService, SupplierUiOrderItem } from "src/domain";

import { OrderItemDetailsController } from "../OrderItemDetailsController";

type RenderedOrderItemProps = {
  orderItem: SupplierUiOrderItem;
  showItemCatalogUnavailability: boolean;
};

const RenderedOrderItem = ({
  orderItem,
  showItemCatalogUnavailability,
}: RenderedOrderItemProps) => {
  const doesItemExistInCatalog = SupplierOrderService.doesItemExistInCatalog(orderItem);
  return (
    <OrderItemDetailsController
      showLineNumber={orderItem.lineNumber > 0}
      keepLeftMargin={true}
      orderItem={orderItem}
      isAvailable={showItemCatalogUnavailability ? doesItemExistInCatalog : true}
      unavailabilityReason="This line item no longer exists in your catalog. You can leave it as-is, or use Edit Order to replace it with another item or remove it"
      testId={`orderDetails_lineItem-${orderItem.lineNumber}`}
    />
  );
};

type OrderItemsProps = {
  orderItems: SupplierUiOrderItem[];
  showItemCatalogUnavailability?: boolean;
  className?: string;
};

export const OrderItems = ({
  orderItems,
  className = "",
  showItemCatalogUnavailability = false,
}: OrderItemsProps) => {
  return (
    <div
      className={classnames(className, "min-w-full")}
      data-testid="orderDetails_orderItemsContainer"
    >
      {orderItems.map((orderItem) => (
        <RenderedOrderItem
          key={orderItem.id || orderItem.supplierIdentifier}
          orderItem={orderItem}
          showItemCatalogUnavailability={showItemCatalogUnavailability}
        />
      ))}
    </div>
  );
};
