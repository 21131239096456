import XIcon from "@mui/icons-material/CloseOutlined";
import PencilIcon from "@mui/icons-material/Edit";
import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";
import UserIcon from "@mui/icons-material/PersonOutline";
import { ChangeEventHandler, useMemo } from "react";

import { DutyFreeDeclarationInfo, getBadgeSettingsFromOrderType } from "@web/common";
import VesselIcon from "@web/common/icons/VesselIcon.svg";
import {
  Agent,
  Badge,
  CircledImage,
  DeliveryDateDetails,
  DeliveryMethod,
  DeliveryPort,
  Heading,
  IconButton,
  Invoicing,
  Label,
  PONumber,
  Paragraph,
  RegularButton,
  StorageLabel,
  SummaryBoxUI,
  Textarea,
  Tooltip,
} from "@web/ui";
import { extractFromISODateString } from "@web/utils";

import { SupplierOrder, SupplierOrderService, ValidatedSupplierOrderForm } from "src/domain";

type Props = {
  editedOrder: ValidatedSupplierOrderForm;
  originalOrder: SupplierOrder;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onEditOrder: () => void;
  note: string;
  handleNoteChange: ChangeEventHandler<HTMLTextAreaElement>;
  noteError?: string;
  nrItemsNotInCatalog: number;
  canShowOrderType: boolean;
};

export const ConfirmOrder = ({
  editedOrder,
  originalOrder,
  isDisabled,
  isLoading,
  note,
  handleNoteChange,
  onSubmit,
  onCancel,
  onEditOrder,
  noteError,
  nrItemsNotInCatalog,
  canShowOrderType,
}: Props) => {
  const lineItemsDisclaimer = useMemo(() => {
    if (nrItemsNotInCatalog === 0) {
      return;
    }

    return (
      <Tooltip
        className="flex gap-1 items-center mt-1"
        tooltipText="You can confirm this order as-is, or use Edit Order to change it"
      >
        <ExclamationCircleIcon className="w-4 text-warningDefault flex-none" />{" "}
        <Paragraph size="300" color="text-textIcon-blackSecondary">
          {nrItemsNotInCatalog} {nrItemsNotInCatalog > 1 ? "items" : "item"} no longer{" "}
          {nrItemsNotInCatalog > 1 ? "exist" : "exists"} in your catalog
        </Paragraph>
      </Tooltip>
    );
  }, [nrItemsNotInCatalog]);

  return (
    <div className="flex flex-col bg-neutral_100 min-h-full">
      <div className="flex shadow w-full h-7 bg-neutral_0">
        <div className="w-[1024px] px-8 mx-auto flex">
          <div className="flex justify-between relative w-full">
            <div className="absolute w-full h-full flex items-center justify-center">
              <div className="m-auto">
                <Label size="200">{originalOrder.customerName}</Label>{" "}
                <Label size="200" color="text-textIcon-blackSecondary">
                  {originalOrder.orderId}
                </Label>
              </div>
            </div>
            <IconButton
              className="my-auto z-10"
              size="large"
              variant="secondary"
              shape="circle"
              label="Return"
              Icon={XIcon}
              data-testid="closeHeaderLink"
              onClick={onCancel}
            />
            <RegularButton
              className="my-auto z-10"
              variant="secondary"
              size="large"
              label="Edit Order"
              LeadingIcon={PencilIcon}
              onClick={onEditOrder}
            />
          </div>
        </div>
      </div>
      <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10 flex w-full">
        <div className="flex-grow bg-neutral_200 pb-5 flex flex-col max-w-[550px] rounded-lg p-5">
          <Heading size="300" className="mb-4 flex items-center">
            Order Information
            {canShowOrderType && (
              <Badge
                {...getBadgeSettingsFromOrderType({
                  orderType: originalOrder.orderType,
                  text: originalOrder.catalogName,
                })}
                size="s"
                className="ml-2 mr-auto"
              />
            )}
          </Heading>
          <div className="flex mb-5">
            <div className="flex flex-col w-1/2 pr-2">
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className=" uppercase mb-1"
              >
                Origin
              </Paragraph>
              <div className="flex">
                <CircledImage Icon={VesselIcon} size={6} hashKey={originalOrder.vessel.name} />
                <div className="flex flex-col pl-3 truncate">
                  <Label size="200" className="truncate">
                    {originalOrder.vessel.name}
                  </Label>
                  <Label size="200" color="text-textIcon-blackSecondary">
                    {originalOrder.vessel.imoNumber}
                  </Label>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className=" uppercase mb-1"
              >
                point of contact
              </Paragraph>
              <div className="flex">
                <CircledImage
                  Icon={() => <UserIcon className="text-textIcon-whitePrimary w-4" />}
                  size={6}
                  hashKey={originalOrder.vessel.contactInformation?.name}
                />
                <div className="flex flex-col pl-3 truncate">
                  <Label size="200" className="truncate">
                    {originalOrder.vessel.contactInformation?.name}
                  </Label>
                  <Label size="200" color="text-textIcon-blackSecondary">
                    {originalOrder.vessel.contactInformation?.email}
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-3" />
          <div className="flex flex-col gap-3 py-3">
            <DeliveryPort
              portName={originalOrder.port.name}
              portCode={originalOrder.port.locationCode}
            />
            <DutyFreeDeclarationInfo dutyFreeDeclaration={originalOrder.dutyFreeDeclaration} />
            <DeliveryDateDetails
              date={extractFromISODateString(originalOrder.deliveryDate, "justDate")}
              hour={extractFromISODateString(originalOrder.deliveryDate, "justTime")}
              label="delivery date"
            />
            <Agent variant="expanded" agentInformation={originalOrder.agentInformation} />
            <div className="grid grid-cols-2">
              {!!originalOrder.invoiceAccount && (
                <Invoicing invoiceAccount={originalOrder.invoiceAccount} />
              )}
              {!!originalOrder.customerOrderId && (
                <PONumber poNumber={originalOrder.customerOrderId} />
              )}
              {!!originalOrder.storageLabel && <StorageLabel label={originalOrder.storageLabel} />}
              {!!originalOrder.deliveryDetails && (
                <DeliveryMethod deliveryMethod={originalOrder.deliveryDetails} />
              )}
            </div>
          </div>
          <div className="pt-3 flex-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary" className=" pb-2 uppercase">
              Add a note
            </Paragraph>
            <Textarea
              data-testid="orderConfirmField-note"
              parentClassName="py-0"
              value={note}
              onChange={handleNoteChange}
              errorMessage={noteError}
            />
          </div>
        </div>
        <div className="ml-7 w-1/3">
          <SummaryBoxUI
            title="Price breakdown"
            onSubmit={onSubmit}
            nrLineItems={editedOrder.items.length}
            lineItemsDisclaimer={lineItemsDisclaimer}
            nrRfqItems={0}
            nrExtraItems={0}
            isLoading={isLoading}
            ctaLabel="Confirm Order"
            showCtaButton={true}
            vatPrice={SupplierOrderService.formatZeroAmountInOrderCurrency(editedOrder)}
            totalPrice={SupplierOrderService.formatTotalGrossAmount(editedOrder)}
            totalItemsPrice={SupplierOrderService.formatOrderItemsTotalAmount(
              editedOrder.items,
              editedOrder.totalGrossAmount.currencyCode
            )}
            additionalCosts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
              editedOrder.amountAdditionalCosts
            )}
            discounts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
              editedOrder.amountDiscounts
            )}
            isSubmitDisabled={isDisabled}
            supplier={undefined}
          />
        </div>
      </div>
    </div>
  );
};
