import AddOutlined from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";

import { Heading, RegularButton } from "@web/ui";

import { RoutesConfig } from "src/admin/config/routes";

import { Layout } from "../../components";
import { SuppliersTable } from "./components";

export const ManageSuppliers: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="flex justify-between">
        <Heading size="300">All Suppliers</Heading>
        <RegularButton
          variant="primary"
          size="large"
          label="Add Supplier"
          LeadingIcon={AddOutlined}
          onClick={() => navigate(RoutesConfig.addSupplier)}
          data-testid="addSupplierButton"
        />
      </div>
      <SuppliersTable />
    </Layout>
  );
};
