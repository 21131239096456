import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import { Label, MuiIcon, SectionHeaderLink, SimpleBadgeLink } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useOrdersMetadata } from "src/domain";

export const Sidebar: React.FC = () => {
  const ordersQuery = useOrdersMetadata();

  const unseenIncomingOrdersCount = ordersQuery.isSuccess
    ? ordersQuery.data.unseenIncomingOrdersCount
    : 0;
  const incomingOrdersCount = ordersQuery.isSuccess ? ordersQuery.data.incomingOrdersCount : "";

  return (
    <div className="py-6 pl-8 pr-5 bg-neutral_100 w-1/5 min-w-[270px]">
      <div className="flex items-center gap-2 rounded-lg py-2 px-3">
        <MuiIcon Icon={InboxOutlinedIcon} className="w-5 text-textIcon-blackPrimary" aria-hidden />
        <Label size="100" color="text-textIcon-blackPrimary">
          Active Orders
        </Label>
      </div>
      <SimpleBadgeLink
        text={`Incoming ${incomingOrdersCount ? `(${incomingOrdersCount})` : ""}`}
        pathname={RoutesConfig.home}
        search="status=incoming"
        badge={unseenIncomingOrdersCount}
      />
      <SimpleBadgeLink text="Confirmed" pathname={RoutesConfig.home} search="status=confirmed" />
      <SimpleBadgeLink text="Delivered" pathname={RoutesConfig.home} search="status=delivered" />
      <SimpleBadgeLink text="Rejected" pathname={RoutesConfig.home} search="status=rejected" />
      <SimpleBadgeLink text="Cancelled" pathname={RoutesConfig.home} search="status=cancelled" />
      <SimpleBadgeLink
        text="Cancellation requests"
        pathname={RoutesConfig.home}
        search="status=cancellationRequested"
      />
      <SectionHeaderLink
        className="mt-4"
        text="Closed Orders"
        Icon={Inventory2OutlinedIcon}
        pathname={RoutesConfig.home}
        search="closed=true"
      />
    </div>
  );
};
