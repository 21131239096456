import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Money } from "@web/models";
import { DropdownItem, Modal, RegularDropdownItem } from "@web/ui";
import { isDefined, usePrevious } from "@web/utils";

import {
  OrderItemDetailsModal,
  OrderItemQuantityChange,
  ReplaceItemModal,
  SupplierOrderItemForm,
  SupplierOrderService,
  ValidatedSupplierOrderForm,
  useOrderEditsSync,
} from "src/domain";

import { OrderItemEdit } from "../OrderItemEdit";

interface Props {
  orderId: string;
  orderVersionId: number;
  orderItem: SupplierOrderItemForm;
  orderItemIndex: number;
  originalOrderItem?: SupplierOrderItemForm;
  excludedItemIds: string[];
  replaceItem: (itemToReplaceIndex: number, replacement: SupplierOrderItemForm) => void;
}

export const OrderItemEditController = ({
  orderId,
  orderVersionId,
  orderItem,
  orderItemIndex,
  originalOrderItem,
  replaceItem,
  excludedItemIds,
}: Props) => {
  const { control, setValue, getValues } = useFormContext<ValidatedSupplierOrderForm>();
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { addEdit, editsToRollback, didEditsToRollbackChange } = useOrderEditsSync();

  const uiOrderItem = SupplierOrderService.convertOrderItemDataToUiModel(
    orderItem,
    orderItem.lineNumber
  );

  // Quantity ordered originally. If there is no originalOrderItem, then this is a newly added item,
  // and thus we do not have info about the original quantity, so we default to 1.
  const originallyOrderedQuantity = originalOrderItem?.quantity || 1;

  // Quantity set while editing the order
  const modifiedQuantity = useWatch({
    control,
    name: `items.${orderItemIndex}.quantity`,
  });

  // Newly added items are not allowed to be replaced, and they do not have originally ordered data,
  // so we can add checking for originalOrderItem existence to `isReplacement` condition.
  const isReplacement =
    SupplierOrderService.isOrderItemReplacement(orderItem) && !!originalOrderItem;

  // New items do not have original order data (they don't exist on original order)
  const isNewItem = !originalOrderItem;

  const isRemoved = modifiedQuantity === 0;
  const doesItemExistInCatalog = SupplierOrderService.doesItemExistInCatalog(orderItem);
  const quantityForCalculations =
    isReplacement && orderItem.skuDetails
      ? SupplierOrderService.matchQuantityForReplacementItem(
          originalOrderItem,
          orderItem.skuDetails
        )
      : originallyOrderedQuantity;

  /**
   * LineItemQuantity
   */
  const minimumOrderQuantity = SupplierOrderService.getMinimumOrderQuantityForSupplier();

  /**
   * TotalUnitOfMeasure
   */
  const salesEntityQuantity = useMemo(
    () => SupplierOrderService.getSalesEntityQuantity(orderItem),
    [orderItem]
  );

  /**
   * Line total
   */
  // Calculated based on current quantity
  const orderItemTotalAmount = useMemo(
    () => SupplierOrderService.getOrderItemTotalAmount(orderItem, modifiedQuantity),
    [orderItem, modifiedQuantity]
  );

  // Calculated only for display in UI
  const presentedItemTotalAmount = useMemo(
    () =>
      isRemoved
        ? SupplierOrderService.getOrderItemTotalAmount(orderItem, quantityForCalculations)
        : orderItemTotalAmount,
    [isRemoved, orderItem, orderItemTotalAmount, quantityForCalculations]
  );

  const presentedItemTotal: Money = useMemo(
    () => ({
      amount: presentedItemTotalAmount,
      currencyCode: orderItem.totalAmount.currencyCode,
    }),
    [presentedItemTotalAmount, orderItem.totalAmount.currencyCode]
  );

  /**
   * Unit total
   */
  const unitPrice: Money = useMemo(
    () => SupplierOrderService.getSingleItemPrice(orderItem),
    [orderItem]
  );

  /**
   * Total entity quantity
   */
  const entityQuantity = useMemo(
    () => SupplierOrderService.getTotalEntityQuantity(salesEntityQuantity, modifiedQuantity),
    [modifiedQuantity, salesEntityQuantity]
  );

  /**
   * Set values to form
   */
  useEffect(() => {
    // Set form item's total amount in reaction to orderItemTotalAmount change
    setValue(`items.${orderItemIndex}.totalAmount.amount`, orderItemTotalAmount);
  }, [setValue, orderItemIndex, orderItemTotalAmount]);

  useEffect(() => {
    // Set total units' quantity in the form data in reaction to quantity change
    setValue(`items.${orderItemIndex}.entityQuantity`, entityQuantity);
  }, [setValue, orderItemIndex, entityQuantity]);

  //-----------------------------------------------------------------

  const prevEntityQuantity = usePrevious(entityQuantity);
  const prevModifiedQuantity = usePrevious(modifiedQuantity);

  // Rollback quantity value to the oldest one that errored during sync - which will be the first one in the collection
  const oldestErroredQuantityEdit = useMemo(
    () =>
      editsToRollback.find(
        (edit) => edit.type === "orderItemQuantityChange" && edit.meta.orderItemId === orderItem.id
      ) as OrderItemQuantityChange | undefined,
    [editsToRollback, orderItem.id]
  );

  useEffect(() => {
    if (
      modifiedQuantity === prevModifiedQuantity ||
      !isDefined(prevModifiedQuantity) ||
      !isDefined(modifiedQuantity) ||
      !isDefined(prevEntityQuantity) ||
      !isDefined(entityQuantity)
    ) {
      return;
    }

    // Do not push a value that occurred because of a rollback. `editsToRollback` reset with next sync,
    // so we can use it as a flag to check if the value was set because of a rollback.
    if (
      oldestErroredQuantityEdit &&
      modifiedQuantity === oldestErroredQuantityEdit.oldValue.quantity
    ) {
      return;
    }

    // Push new quantity value to be synced when it changes
    addEdit({
      type: "orderItemQuantityChange",
      meta: {
        orderId,
        oldOrderVersionId: orderVersionId,
        orderItemId: orderItem.id,
      },
      oldValue: {
        quantity: prevModifiedQuantity,
        entityQuantity: prevEntityQuantity,
      },
      newValue: { quantity: modifiedQuantity, entityQuantity },
    });
  }, [
    addEdit,
    modifiedQuantity,
    prevModifiedQuantity,
    orderId,
    orderVersionId,
    orderItem.id,
    oldestErroredQuantityEdit,
    prevEntityQuantity,
    entityQuantity,
  ]);

  useEffect(() => {
    if (!didEditsToRollbackChange || !oldestErroredQuantityEdit) {
      return;
    }

    setValue(`items.${orderItemIndex}.quantity`, oldestErroredQuantityEdit.oldValue.quantity);
  }, [didEditsToRollbackChange, setValue, orderItemIndex, oldestErroredQuantityEdit]);

  //-----------------------------------------------------------------

  /**
   * Item actions
   */
  const onReplaceItem = useCallback(
    (replacement: SupplierOrderItemForm) => {
      replaceItem(orderItemIndex, replacement);
    },
    [orderItemIndex, replaceItem]
  );

  const getItemToReplace = useCallback(
    () => (isReplacement ? originalOrderItem : getValues(`items.${orderItemIndex}`)),
    [getValues, isReplacement, orderItemIndex, originalOrderItem]
  );

  const onRestoreOriginalItem = useCallback(() => {
    if (isReplacement) {
      replaceItem(orderItemIndex, originalOrderItem);
    } else {
      throw new Error("Cannot restore original item when it was not replaced");
    }
  }, [isReplacement, orderItemIndex, originalOrderItem, replaceItem]);

  const openDetailsModal = () => setIsDetailsModalOpen(true);
  const closeDetailsModal = () => setIsDetailsModalOpen(false);
  const openReplacementModal = () => setIsReplacementModalOpen(true);
  const closeReplacementModal = () => setIsReplacementModalOpen(false);

  const onRestoreOriginalQuantity = useCallback(() => {
    if (isReplacement) {
      setValue(`items.${orderItemIndex}.quantity`, quantityForCalculations);
    } else {
      setValue(`items.${orderItemIndex}.quantity`, originallyOrderedQuantity);
    }
  }, [setValue, orderItemIndex, originallyOrderedQuantity, isReplacement, quantityForCalculations]);

  /**
   * Dropdown items config
   */
  const dropdownItems: DropdownItem[] = useMemo(
    () => [
      ...(isRemoved && !isReplacement
        ? [
            {
              key: "restoreQuantity",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Restore"
                  variant="basic"
                  onClick={() => {
                    onRestoreOriginalQuantity();
                  }}
                  data-testid="dotsDropdown_restoreOriginalItemQuantity"
                />
              ),
            },
          ]
        : []),
      ...(SupplierOrderService.isAddedItem(orderItem)
        ? []
        : [
            ...(isRemoved && isReplacement
              ? [
                  {
                    key: "restoreReplacement",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label="Restore Replacement"
                        variant="basic"
                        onClick={() => {
                          onRestoreOriginalQuantity();
                        }}
                        data-testid="dotsDropdown_restoreReplacementQuantity"
                      />
                    ),
                  },
                ]
              : []),
            ...(isReplacement
              ? [
                  {
                    key: "restoreOriginalItem",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label="Restore Original Item"
                        variant="basic"
                        onClick={() => {
                          onRestoreOriginalItem();
                        }}
                        data-testid="dotsDropdown_restoreOriginalItem"
                      />
                    ),
                  },
                ]
              : []),
            ...(!isRemoved && !isNewItem
              ? [
                  {
                    key: "replaceItem",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label={isReplacement ? "Replace With Another Item" : "Replace"}
                        variant="basic"
                        onClick={() => {
                          openReplacementModal();
                        }}
                        data-testid="dotsDropdown_replace"
                      />
                    ),
                  },
                ]
              : []),
          ]),
    ],
    [
      isNewItem,
      isRemoved,
      isReplacement,
      onRestoreOriginalItem,
      onRestoreOriginalQuantity,
      orderItem,
    ]
  );

  const orderedQuantityValue =
    isRemoved && isDefined(originalOrderItem) && orderItem.skuDetails
      ? SupplierOrderService.matchQuantityForReplacementItem(
          originalOrderItem,
          orderItem.skuDetails
        )
      : originallyOrderedQuantity;

  return (
    <>
      <Modal isOpen={isReplacementModalOpen} closeModal={closeReplacementModal}>
        <ReplaceItemModal
          orderId={orderId}
          itemToReplace={getItemToReplace()}
          onReplaceItem={onReplaceItem}
          closeModal={closeReplacementModal}
          excludedItemIds={excludedItemIds}
        />
      </Modal>
      <Modal isOpen={isDetailsModalOpen} closeModal={closeDetailsModal}>
        <OrderItemDetailsModal
          lineNumber={uiOrderItem.lineNumber}
          name={uiOrderItem.name}
          impaCode={uiOrderItem.impaCode || ""}
          itemId={uiOrderItem.supplierIdentifier || ""}
          closeModal={closeDetailsModal}
        />
      </Modal>
      <OrderItemEdit
        orderItem={uiOrderItem}
        isRemoved={isRemoved}
        isAvailable={doesItemExistInCatalog}
        unavailabilityReason="This line item no longer exists in your catalog. You can leave it as-is, replace it with another item, or remove it from the order"
        minimumOrderQuantity={minimumOrderQuantity}
        salesEntityQuantity={salesEntityQuantity}
        presentedItemTotal={presentedItemTotal}
        unitPrice={unitPrice}
        orderedQuantity={orderedQuantityValue}
        control={control}
        orderItemIndex={orderItemIndex}
        modifiedQuantity={modifiedQuantity}
        showItemLineNumber={!isNewItem}
        dropdownItems={dropdownItems}
        openDetailsModal={openDetailsModal}
      />
    </>
  );
};
