import { useState } from "react";

import { Modal } from "@web/ui";

import { OrderItemDetails, OrderItemDetailsModal, SupplierUiOrderItem } from "src/domain";

interface Props {
  orderItem: SupplierUiOrderItem;
}

export const OrderReplacedItemController = ({ orderItem }: Props) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  return (
    <>
      <Modal isOpen={isDetailsModalOpen} closeModal={() => setIsDetailsModalOpen(false)}>
        <OrderItemDetailsModal
          lineNumber={orderItem.lineNumber}
          name={orderItem.name}
          impaCode={orderItem.impaCode || ""}
          itemId={orderItem.supplierIdentifier || ""}
          closeModal={() => setIsDetailsModalOpen(false)}
        />
      </Modal>
      <OrderItemDetails
        orderItem={orderItem}
        onNameClick={() => setIsDetailsModalOpen(true)}
        isRemoved={true}
        isAvailable={true}
        showItemLineNumber={false}
        keepLeftMargin={true}
        spacing="tight"
        testId="replacedOrderItem"
      />
    </>
  );
};
