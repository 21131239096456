import { Dispatch, createContext, useContext } from "react";

import { UserScopesState, UserScopesStateAction } from "./models";
import { defaultUserScopesState } from "./reducer";

export const UserScopesContext = createContext<
  [state: UserScopesState, action: Dispatch<UserScopesStateAction>]
>([
  defaultUserScopesState,
  () => {
    return;
  },
]);

export const useUserScopesContext = () => useContext(UserScopesContext);
