import MailIcon from "@mui/icons-material/EmailOutlined";

import VesselIcon from "@web/common/icons/VesselIcon.svg";
import { CircledImage, Label, MuiIcon } from "@web/ui";

interface VesselContactProps {
  imoNumber: string;
  vesselName?: string;
  onContactClick: () => void;
}

export const RequesterContact: React.FC<VesselContactProps> = ({
  imoNumber,
  vesselName,
  onContactClick,
}) => {
  return (
    <div className="flex justify-between pb-4">
      <div className="flex w-full">
        <CircledImage Icon={VesselIcon} size={6} hashKey={vesselName} className="my-auto" />
        <div className="flex flex-col pl-1 truncate">
          <Label size="200" className="truncate">
            {vesselName}
          </Label>
          <Label size="200" color="text-textIcon-blackSecondary">
            {imoNumber}
          </Label>
        </div>
        <div
          className="ml-auto w-5 text-textIcon-whiteDisabled cursor-pointer flex-shrink flex"
          onClick={onContactClick}
        >
          <MuiIcon Icon={MailIcon} className="w-4.5" data-testid="requesterContact_mailIcon" />
        </div>
      </div>
    </div>
  );
};
