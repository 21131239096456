import AddOutlined from "@mui/icons-material/AddOutlined";
import CheckIcon from "@mui/icons-material/CheckOutlined";
import { Link } from "react-router-dom";

import { MuiIcon, Paragraph, RegularButton } from "@web/ui";

import { Layout } from "src/admin/components";
import { RoutesConfig } from "src/admin/config/routes";

export const NewSupplierSuccess: React.FC = () => {
  return (
    <Layout>
      <div className="flex flex-col justify-center m-auto border-md rounded-lg border p-5 w-[320px]">
        <div className="m-auto w-7 h-7 bg-successBackground rounded-full flex">
          <MuiIcon Icon={CheckIcon} className="w-6 text-successDefault m-auto" />
        </div>
        <Paragraph size="100" className="mt-4">
          A new supplier has been added
        </Paragraph>
        <Paragraph size="200" className="mt-5 px-2 text-center">
          The contact person will soon receive an invitation to log into the Supplier Portal.{" "}
        </Paragraph>
        <Link to={RoutesConfig.addSupplier} className="mt-6 flex flex-col w-full">
          <RegularButton
            variant="primary"
            size="large"
            label="Add another Supplier"
            LeadingIcon={AddOutlined}
            onClick={() => null}
            data-testid="addAnotherSupplierButton"
          />
        </Link>
        <Link to={RoutesConfig.home} className="mt-4 flex flex-col w-full">
          <RegularButton
            variant="secondary"
            size="large"
            label="Back to All Suppliers"
            onClick={() => null}
            data-testid="backToAllSuppliersButton"
          />
        </Link>
      </div>
    </Layout>
  );
};
