import { AdminApp } from "src/admin";
import { useUserRoles } from "src/hooks";
import { Routes } from "src/routes";

export const ApplicationSwitch = () => {
  const { isSupplier, isSupervisor, isAdmin } = useUserRoles();

  if (isSupplier || isSupervisor) {
    return <Routes />;
  }

  if (isAdmin) {
    return <AdminApp />;
  }

  return <div data-testid="not-matched">Application not matched for this user</div>;
};
