import { useParams } from "react-router-dom";

import { RoutesParams } from "src/config/routes";

import { OrderEditFormQuery } from "./components";

export const OrderEdit = () => {
  const { [RoutesParams.ORDER_ID]: orderId } = useParams() as { orderId: string };

  return <OrderEditFormQuery orderId={orderId} />;
};
