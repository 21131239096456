import { SvgIconComponent } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";

import { Label, MuiIcon, Paragraph } from "@web/ui";

interface Props {
  closeModal: () => void;
  title: string;
  Icon: SvgIconComponent;
  name: string;
  label?: string;
  phone?: string;
  email?: string;
}

export const ContactInfoModal: React.FC<Props> = ({
  closeModal,
  title,
  Icon,
  name,
  label,
  phone,
  email,
}) => {
  return (
    <div className="inline-block bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <span className="sr-only">Close</span>
          <MuiIcon
            Icon={CloseOutlinedIcon}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="flex flex-col items-center text-center w-96 pb-5">
        <div className="mt-3 mb-2 text-center">
          <Paragraph size="100">{title}</Paragraph>
        </div>
        <Icon className="w-5 text-textIcon-blackSecondary mt-1" />
        <div className="mt-2">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {name}
          </Paragraph>
          {!!label && (
            <Paragraph size="300" className="text-neutral_700">
              {label}
            </Paragraph>
          )}
        </div>
        {!!(phone || email) && (
          <div className="flex flex-col mt-4">
            <hr />
            {!!phone && (
              <div className="flex items-center self-center mt-4">
                <PhoneIcon className="w-5 text-textIcon-whiteDisabled mr-2" />
                <Label size="200" color="text-textIcon-blackSecondary">
                  {phone}
                </Label>
              </div>
            )}
            {!!email && (
              <div className="flex items-center self-center mt-4">
                <MailIcon className="w-5 text-textIcon-whiteDisabled mr-2" />
                <Label size="200" color="text-textIcon-blackSecondary">
                  {email}
                </Label>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
