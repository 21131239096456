import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import { SupplierOrderService, useOrderByIdQuery } from "src/domain";

import { OrderEditForm } from "../OrderEditForm";

interface Props {
  orderId: string;
}

export const OrderEditFormQuery = ({ orderId }: Props) => {
  const { setSystemMessage } = useSystemMessages();

  const originalOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "OLDEST",
    select: SupplierOrderService.convertSupplierOrderToDraftFormData,
  });
  const {
    isPending: isOriginalOrderPending,
    isFetching: isOriginalOrderFetching,
    data: originalOrderFormData,
  } = originalOrderQuery;

  const latestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
    select: SupplierOrderService.convertSupplierOrderToDraftFormData,
  });
  const {
    isPending: isLatestOrderPending,
    isFetching: isLatestOrderFetching,
    data: latestOrderFormData,
  } = latestOrderQuery;

  const isAnyQueryPendingOrFetching =
    isOriginalOrderPending ||
    isOriginalOrderFetching ||
    isLatestOrderPending ||
    isLatestOrderFetching;

  const isOrderEditable: boolean | undefined = isDefined(originalOrderFormData)
    ? originalOrderFormData.editable
    : undefined;

  const navigate = useNavigate();

  useEffect(() => {
    // Only verify after we have the data from the query
    if (isDefined(isOrderEditable) && !isOrderEditable) {
      setSystemMessage({
        type: "failure",
        message: "This order is not available for editing",
      });

      navigate(generatePath(RoutesConfig.orderDetails, { [RoutesParams.ORDER_ID]: orderId }));
    }
  }, [isOrderEditable, navigate, orderId, setSystemMessage]);

  if (
    isAnyQueryPendingOrFetching ||
    // If order is not editable, keep the Pending state until the user is redirected away
    !isOrderEditable ||
    // Satisfy compiler making sure these are defined
    !originalOrderFormData ||
    !latestOrderFormData
  ) {
    return <Loading />;
  }

  return (
    <OrderEditForm
      originalOrderFormData={originalOrderFormData}
      editedOrderFormData={latestOrderFormData}
    />
  );
};
