import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";
import DotsVerticalIcon from "@mui/icons-material/MoreVert";
import { Control, Controller } from "react-hook-form";

import { TotalUnitOfMeasure } from "@web/common";
import { Money } from "@web/models";
import { Dropdown, DropdownItem, IconButton, LineItemQuantity, Tooltip } from "@web/ui";

import {
  OrderItem,
  OrderItemPriceTotals,
  SupplierUiOrderItem,
  ValidatedSupplierOrderForm,
} from "src/domain";

interface Props {
  dropdownItems: DropdownItem[];
  orderItem: SupplierUiOrderItem;
  orderItemIndex: number;
  isRemoved: boolean;
  isAvailable: boolean;
  unavailabilityReason?: string;
  unitPrice: Money;
  presentedItemTotal: Money;
  modifiedQuantity: number;
  salesEntityQuantity: number;
  minimumOrderQuantity: number;
  orderedQuantity: number;
  openDetailsModal?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  control: Control<ValidatedSupplierOrderForm>;
  showItemLineNumber: boolean;
}

export const OrderItemEdit = ({
  dropdownItems,
  orderItem,
  orderItemIndex,
  isRemoved = false,
  isAvailable = false,
  unavailabilityReason,
  openDetailsModal,
  control,
  orderedQuantity,
  minimumOrderQuantity,
  salesEntityQuantity,
  modifiedQuantity,
  presentedItemTotal,
  unitPrice,
  showItemLineNumber,
}: Props) => {
  const { impaCode, supplierIdentifier, lineNumber, name, measurementUnit } = orderItem;
  return (
    <OrderItem
      impaCode={impaCode}
      itemId={supplierIdentifier}
      itemLineNumber={lineNumber}
      showItemLineNumber={showItemLineNumber}
      keepLeftMargin={true}
      itemName={name}
      isRemoved={isRemoved}
      isAvailable={isAvailable}
      onNameClick={openDetailsModal}
      testId="orderItemEdit"
      renderAdditionalData={(columnWidth) => (
        <div className="flex-none grid grid-cols-3">
          <div className={`flex flex-col items-start justify-around ${columnWidth}`}>
            {!isRemoved && (
              <Controller
                name={`items.${orderItemIndex}.quantity`}
                control={control}
                render={({ field }) => (
                  <LineItemQuantity
                    initialQuantity={orderedQuantity}
                    quantity={field.value}
                    min={minimumOrderQuantity}
                    onQuantityChange={(newQuantity) => field.onChange(newQuantity)}
                  />
                )}
              />
            )}
          </div>
          <div className={`flex flex-col items-start justify-around pl-4 ${columnWidth}`}>
            <TotalUnitOfMeasure
              className="justify-center"
              lineThrough={isRemoved}
              measurementUnit={measurementUnit}
              quantityInBasket={isRemoved ? orderedQuantity : modifiedQuantity}
              salesEntityQuantity={salesEntityQuantity}
              variant="positive"
            />
          </div>
          <div className={`flex flex-col items-end justify-around ${columnWidth}`}>
            <OrderItemPriceTotals
              itemTotal={presentedItemTotal}
              unitPrice={unitPrice}
              isRemoved={isRemoved}
              measurementUnit={measurementUnit}
            />
          </div>
          {!isAvailable && !isRemoved && (
            <div className="absolute top-[20px] -left-4.5">
              {unavailabilityReason ? (
                <Tooltip tooltipClassName="w-20" tooltipText={unavailabilityReason}>
                  <ExclamationCircleIcon className="w-4.5 text-warningDefault" />
                </Tooltip>
              ) : (
                <ExclamationCircleIcon className="w-4.5 text-warningDefault" />
              )}
            </div>
          )}
          {dropdownItems.length > 0 && (
            <div className="absolute top-[9px] -right-[42px]">
              <Dropdown
                hAlignTo="end"
                vAlignTo="bottom"
                items={dropdownItems}
                data-testid="orderItemEdit_dotsDropdown"
              >
                <IconButton
                  size="large"
                  variant="secondary"
                  shape="circle"
                  label="More actions"
                  Icon={DotsVerticalIcon}
                  data-testid="orderItemEdit_dotsDropdown_trigger"
                  isControlled
                />
              </Dropdown>
            </div>
          )}
        </div>
      )}
    />
  );
};
